import React, { useContext, useEffect, useState } from "react";
import { Grid, Button, CircularProgress } from "@material-ui/core";
import CustomModal from "../../../partials/CustomModal";

import AvailableDatasetCard from "./AvailableDatasetCard";
import { LocationSelectContext } from "../../../context/LocationSelectPageContext";

import {
  DatasetSelectionModalPropTypes,
  useDatasetSelectionModalStyles,
} from "./useStyles";

import {
  CordexContent,
  Ukpc18Content,
  NoaaLocaContent,
  Cmip6Content,
} from "./TextContent";
import ServicesApi from "../../../../api/services";
import { ModelApiResponseType } from "../../../../../types/types";

export const availableDatasets: Array<{
  name: string;
  isAvailable: boolean;
}> = [
  { name: "UKCP-18", isAvailable: false },
  { name: "CORDEX", isAvailable: true },
  { name: "NOAA LOCA", isAvailable: false },
  { name: "CMIP6", isAvailable: false },
];

const DatasetSelectionModal = (props: DatasetSelectionModalPropTypes) => {
  const { open, closeModalHandler } = props;

  const classes = useDatasetSelectionModalStyles();

  const {
    selectedDatasetState,
    focusOnHistoryProjectionDpdState,
    datasetSelectionModalState,
    projectDescriptionModalState,
    climateDatasetModalState,
    doesEachFieldOfConfigDatasetCardHasValueState,
    retrivableData,
    selectedClimateVariableSourceState,
  } = useContext(LocationSelectContext);

  const [retriveData] = retrivableData;

  const { selectedDataset, setSelectedDataset } = selectedDatasetState;

  const { setIsDatasetSelectionModalOpen } = datasetSelectionModalState;

  const { setIsProjectDescriptionModalOpen } = projectDescriptionModalState;

  const { setIsClimateDatasetModalOpen } = climateDatasetModalState;

  const { selectedClimateVariableSource } = selectedClimateVariableSourceState;

  const {
    doesEachFieldOfConfigDatasetCardHasValue,
  } = doesEachFieldOfConfigDatasetCardHasValueState;

  const {
    setShouldFocusOnHistoryProjectionDpd,
  } = focusOnHistoryProjectionDpdState;
  //eslint-disable-next-line
  const [filter, setFilter] = useState("Projections");

  const [isLoading, setIsLoading] = useState(false);

  const [modelResponse, setModelResponse] = useState<ModelApiResponseType>(
    {} as ModelApiResponseType
  );

  const fetchUsedModels = async () => {
    try {
      setIsLoading(true);
      const { data } = await ServicesApi.get<ModelApiResponseType>(
        "/model/selection",
        {
          params: {
            user_region: retriveData.region,
            user_var: selectedClimateVariableSource,
          },
        }
      );

      setModelResponse(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedClimateVariableSource && retriveData.region) {
      fetchUsedModels();
    }
    //eslint-disable-next-line
  }, [selectedClimateVariableSource, retriveData.region]);

  const renderContent = () => {
    switch (selectedDataset) {
      case "UKCP-18":
        return <Ukpc18Content />;

      case "CORDEX":
        return <CordexContent data={modelResponse} />;

      case "NOAA LOCA":
        return <NoaaLocaContent />;

      case "CMIP6":
        return <Cmip6Content />;
      default:
        break;
    }
  };

  return (
    <CustomModal
      isOpen={open}
      title="Available Datasets"
      headingMaxWidth="31.75rem"
      headingFontSize="1.5rem"
      onClick={closeModalHandler}
    >
      <div
        style={{
          marginTop: "1rem",
          width: "100%",
          display: "flex",
        }}
      >
        <div
          style={{
            width: "19.4375rem",

            maxHeight: "30.8rem",
            overflowY: "scroll",
          }}
        >
          {availableDatasets.map((item, i) => (
            <AvailableDatasetCard
              style={{
                marginBottom: i === availableDatasets.length - 1 ? 0 : "",
              }}
              key={i}
              title={item.name}
              selected={item.name === selectedDataset}
              onSelect={() => setSelectedDataset(item.name)}
              isAvailable={item.isAvailable}
            />
          ))}
        </div>

        <div
          className={classes.contentContainer}
          style={{
            justifyContent: isLoading ? "center" : "flex-start",
            alignItems: isLoading ? "center" : "flex-start",
          }}
        >
          {isLoading ? (
            <CircularProgress size={30} color="secondary" />
          ) : (
            renderContent()
          )}
        </div>
      </div>

      <Grid item container justify="center" style={{ marginTop: "2.5rem" }}>
        <Button
          className={classes.btn}
          variant="contained"
          color="secondary"
          disableElevation
          style={{ backgroundColor: "#7CD4F2" }}
          onClick={() => {
            setIsClimateDatasetModalOpen(true);
            closeModalHandler();
          }}
        >
          Go Back
        </Button>

        <Button
          className={classes.btn}
          variant="contained"
          disableElevation
          onClick={() => {
            setShouldFocusOnHistoryProjectionDpd(true);
            setIsDatasetSelectionModalOpen(false);
            setIsProjectDescriptionModalOpen(true);
          }}
          disabled={!doesEachFieldOfConfigDatasetCardHasValue}
          style={{ marginLeft: "3.625rem" }}
        >
          Select &amp; Continue
        </Button>
      </Grid>
    </CustomModal>
  );
};

export default DatasetSelectionModal;

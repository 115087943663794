/* eslint-disable */
import React, { useContext, useState } from "react";

import searchRegionSvg from "../../../../../images/searchRegion.svg";
import searchByCoordinatesSvg from "../../../../../images/searchByCoordinates.svg";
import CustomModal from "../../../partials/CustomModal";

import LevelLocationDetailCard from "./LevelLocationDetailCard";
import { LocationSelectContext } from "../../../context/LocationSelectPageContext";

import { WelcomeToTheArupModalPropTypes } from "./useStyles";

const WelcomeToTheArupModal = (props: WelcomeToTheArupModalPropTypes) => {
  const { open, closeModalHandler } = props;

  const { selectedSearchTypeState } = useContext(LocationSelectContext);

  const { setSelectedSearchType } = selectedSearchTypeState;

  const setSearchTypeAndCloseModalHandler = (searchType: string) => {
    setSelectedSearchType(searchType);
   
    if (searchType === "regionOrCountry") {
    }

    closeModalHandler();
  };

  const [
    dataForLevelLocationDetailCard,
    _setDataForLevelLocationDetailCard,
  ] = useState<
    Array<{
      title: string;
      question: string;
      description: string;
      disabled: boolean;
      src: string;
      imgHeight: string;
      searchType: string;
    }>
  >([
    {
      title: "Search Region or Country",
      question: "Interested in large area assessment? ",
      description:
        "Select continental regions or specific countries if you would like to study.",
      disabled: true,
      src: searchRegionSvg,
      imgHeight: "10.4812rem",
      searchType: "regionOrCountry",
    },
    {
      title: "Search by Coordinates",
      question: "Interested in point locations?",
      description:
        "Select multiple latitude & longitudes within a region to study simultaneously.",
      disabled: false,
      src: searchByCoordinatesSvg,
      imgHeight: "11.0056rem",
      searchType: "coordinates",
    },
  ]);

  return (
    <CustomModal
      title="Welcome to Kliima"
      headingBottomText="the Arup Climate Data Toolkit"
      subTitle="Start your search by region/country or point location"
      isOpen={open}
      onClick={closeModalHandler}
      justifyContent="space-around"
    >
      {dataForLevelLocationDetailCard.map((item, i) => (
        <LevelLocationDetailCard
          onClick={() => setSearchTypeAndCloseModalHandler(item.searchType)}
          key={i}
          title={item.title}
          question={item.question}
          description={item.description}
          disabled={item.disabled}
          src={item.src}
          imgHeight={item.imgHeight}
        />
      ))}
    </CustomModal>
  );
};

export default WelcomeToTheArupModal;

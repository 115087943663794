import React, { useContext, useState } from "react";
import { Button, Grid } from "@material-ui/core";

import CustomModal from "../../../partials/CustomModal";

import ClimateDataSetCard from "./ClimateDataSetCard";

import climateDataSetSvg from "../../../../../images/climateDataSet.svg";

import {
  useClimateDataSetModalStyles,
  ClimateDataSetModalPropsType,
} from "./useStyles";
import { LocationSelectContext } from "../../../context/LocationSelectPageContext";
import Pagination from "../../../partials/Pagination";

const ClimateDataSetModal = (props: ClimateDataSetModalPropsType) => {
  const classes = useClimateDataSetModalStyles();

  const { open, closeModalHandler } = props;

  const {
    datasetSelectionModalState,
    climateDatasetModalState,
    variableSelectionModalState,
    configureDatasetVisibleState,
  } = useContext(LocationSelectContext);

  const { setIsDatasetSelectionModalOpen } = datasetSelectionModalState;

  const { setIsClimateDatasetModalOpen } = climateDatasetModalState;

  const { setIsVariableSelectionModalOpen } = variableSelectionModalState;

  const { setIsConfigureDatasetVisible } = configureDatasetVisibleState;

  // only for rendering content dynamically
  // this data for  the ClimateDataSetCard
  const [climateDataSetCardData] = useState<
    Array<{ title: string; description: string }>
  >([
    {
      title: "Cordex",
      description:
        "CORDEX provides access to dynamically downscaled data for regional divisions covering most land areas in the globe.",
    },
    {
      title: "NOAA LOCA",
      description:
        "LOCA covers North American statistically downscaled data including temperature and precipitation which has been used to inform the National Climate Assessment 4 (NCA4) in the United States.",
    },
    {
      title: "UKCP18",
      description:
        "Kliima provides access to the UKCP18 regional climate model projections at 12km resolution for the UK.",
    },
    {
      title: "CMIP-6",
      description:
        "CMIP-6 projections cover the entire globe and will underpin the next Intergovernmental Panel on Climate Change (IPCC) Assessment Report.",
    },
  ]);

  //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(4);

  // get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // getting current items to show on the page
  const currentItems = climateDataSetCardData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // for paginating to the next page
  // getting pageNumber from the Pagination Component
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <CustomModal
      title="How we use climate datasets..."
      isOpen={open}
      onClick={closeModalHandler}
      paragraph={
        <p
          style={
            {
              // marginTop: "-3rem"
            }
          }
        >
          Several different climate datasets are available in Kliima. Each
          dataset has different characteristics, so it is important to choose
          the dataset that is right for you.
          <br /> <br />
          General Circulation Models (GCM) are physically based models which
          simulate the earth system under current and future climate conditions
          at a relatively coarse resolution. Regional climate models (RCMs)
          provide higher resolution climate projections for a limited region on
          the globe based on boundary conditions set by the coarse resolution
          GCMs. The sources we currently access are:
        </p>
      }
    >
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              flex: 1,
            }}
          >
            {currentItems.map((item, i) => (
              <ClimateDataSetCard
                key={i}
                title={item.title}
                description={item.description}
              />
            ))}
          </div>
          <img
            src={climateDataSetSvg}
            alt="cimate data set"
            className={classes.img}
          />
        </div>
        <Grid item container justify="center" style={{}}>
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            disableElevation
            style={{ backgroundColor: "#ADE5F7" }}
            onClick={() => {
              setIsConfigureDatasetVisible(false);
              setIsClimateDatasetModalOpen(false);
              setIsVariableSelectionModalOpen(true);
            }}
          >
            Go Back
          </Button>
          <Button
            className={classes.btn}
            variant="contained"
            color="secondary"
            disableElevation
            style={{ marginLeft: "3.625rem", backgroundColor: "#F99052" }}
            onClick={() => {
              setIsClimateDatasetModalOpen(false);
              setIsDatasetSelectionModalOpen(true);
            }}
          >
            Continue
          </Button>
        </Grid>
      </>

      <Grid container justify="center">
        {climateDataSetCardData.length > 4 && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={climateDataSetCardData.length}
            paginate={paginate}
            currentPageNumber={currentPage}
          />
        )}
      </Grid>
    </CustomModal>
  );
};

export default ClimateDataSetModal;

import { makeStyles } from "@material-ui/core";

export type ClimateDatasetCardPropTypes = {
  title: string;
  description: string;
};

export const useClimateDataSetCardStyles = makeStyles({
  title: {
    width: "14rem",
    fontWeight: 300,
    fontSize: "1rem",
    lineHeight: "19px",
    color: " #1C1C1C",
    textTransform: "uppercase",
  },
  description: {
    width: "13.9375rem",

    fontSize: "0.75rem",
    lineHeight: "13px",
    color: "#1C1C1C",
  },
  dot: {
    width: "13px",
    height: "13px",
    borderRadius: "100%",
    background: "#4EC0EB",
    marginRight: "7px",
    display: "inline-block",
  },
});

export type ClimateDataSetModalPropsType = {
  open: boolean;
  closeModalHandler: () => void;
};

export const useClimateDataSetModalStyles = makeStyles({
  indicator: {
    width: "16px",
    height: "16px",
    background: "#BEBEBE",
    borderRadius: "100%",
    marginLeft: "8px",
    display: "inline-block",
    cursor: "pointer",
  },
  img: {
    height: "21rem",
    marginLeft: "30px",
  },
  btn: {
    width: "8.7975rem",
    fontSize: "0.8374rem",
    lineHeight: "16px",
    color: " #1C1C1C",
  },
});

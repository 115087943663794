let BASE_API_URL = "https://18.135.149.141";

let AZURE_AUTH_CONFIG = {
  auth: {
    clientId: "75fb7171-7911-4d5f-9d97-d8ef64700aae",
    authority: "https://login.microsoftonline.com/consumers/",
    redirectUri: "http://localhost:3000/",
  },
};

let MAP_BOX_CONFIG = {
  mapboxApiAccessToken:
    "pk.eyJ1IjoiYmhhcmF0ZGlnaXJ5dGUiLCJhIjoiY2tuNHBpZzhrMXR5bjJvbWx6aDZtMnBidyJ9.ESNRB4MXx_XtzSxU4zqEcQ",
  mapStyle: "mapbox://styles/bharatdigiryte/cknowpbv51oka17o1k7c3eyu2",
};

if (process.env.REACT_APP_ENV === "development") {
  // BASE_API_URL = "https://18.135.149.141";
  BASE_API_URL = "https://18.133.109.38";
  AZURE_AUTH_CONFIG = {
    auth: {
      clientId: "75fb7171-7911-4d5f-9d97-d8ef64700aae",
      authority: "https://login.microsoftonline.com/consumers/",
      redirectUri: "https://develop.d2aio0vun6cq2a.amplifyapp.com",
      // redirectUri: "http://localhost:3000",
    },
  };

  MAP_BOX_CONFIG = {
    mapboxApiAccessToken:
      "pk.eyJ1IjoiYmhhcmF0ZGlnaXJ5dGUiLCJhIjoiY2tuNHBpZzhrMXR5bjJvbWx6aDZtMnBidyJ9.ESNRB4MXx_XtzSxU4zqEcQ",
    mapStyle: "mapbox://styles/bharatdigiryte/cknowpbv51oka17o1k7c3eyu2",
  };
}

if (process.env.REACT_APP_ENV === "preproduction") {
  BASE_API_URL = "https://18.135.149.141";
  AZURE_AUTH_CONFIG = {
    auth: {
      clientId: "cb44c30f-54da-45fd-b9b3-ae9e06044861",
      authority: "https://login.microsoftonline.com/arup.onmicrosoft.com",
      redirectUri: "https://www.kliima.arup.com",
    },
  };

  MAP_BOX_CONFIG = {
    mapboxApiAccessToken:
      "pk.eyJ1IjoiYmhhcmF0ZGlnaXJ5dGUiLCJhIjoiY2tuNHBpZzhrMXR5bjJvbWx6aDZtMnBidyJ9.ESNRB4MXx_XtzSxU4zqEcQ",
    mapStyle: "mapbox://styles/bharatdigiryte/cknowpbv51oka17o1k7c3eyu2",
  };
}

let BASE_URL = "http://localhost:3000";

if (process.env.REACT_APP_ENV === "development") {
  BASE_URL = "https://develop.d2aio0vun6cq2a.amplifyapp.com";
}

if (process.env.REACT_APP_ENV === "preproduction") {
  BASE_URL = "https://www.kliima.arup.com";
}
export { BASE_API_URL, BASE_URL, AZURE_AUTH_CONFIG, MAP_BOX_CONFIG };

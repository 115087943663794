import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import { DropzoneArea } from "material-ui-dropzone";
import UploadIcon from "../../Icons/UploadIcon";
import ServicesApi from "../../../api/services";
import $ from "jquery";
import {
  SimpleDialogProps,
  CsvPropsType,
  useCsvPopupStyles,
} from "./useStyles";
import { RegionContext } from "../../context/RegionContext";

function SimpleDialog(props: SimpleDialogProps) {
  const classes = useCsvPopupStyles();
  const { geoJSON, mapViewPort } = useContext(RegionContext);
  const [geoJson, setGeoJson] = geoJSON;
  const [viewport, setViewport] = mapViewPort;
  const { onClose, open } = props;
  const [file, setFile] = useState({
    file: { name: "", path: "", size: "", type: "", all: {} },
  });
  const [loadingData, setloadingData] = useState(false);
  const [noFileSelectes, setnoFileSelectes] = useState(true);

  const handleClose = () => {
    onClose("true");
  };
  const handleCloseonContineue = () => {
    geoJsonSetter(file);
  };

  type CallbackFunctionVariadicAnyReturn = (...args: any[]) => any;
  const handleChange: CallbackFunctionVariadicAnyReturn = (e) => {
    if (e.length > 0) {
      //console.log(e[0].name)
      setFile({
        ...file,
        file: {
          name: e[0].name,
          path: e[0].path,
          size: e[0].size,
          type: e[0].type,
          all: e,
        },
      });
      setnoFileSelectes(false);
    } else {
      setnoFileSelectes(true);
      return null;
    }
  };

  const geoJsonSetter: CallbackFunctionVariadicAnyReturn = (file) => {
    const formData = new FormData();
    formData.set("geo_file", file.file.all[0]);

    const fetchGeoJson = async () => {
      setloadingData(true);
      const { data } = await ServicesApi.post<[]>("geodata.geojson", formData);

      return data;
    };
    fetchGeoJson()
      .catch((e) => {})
      .then((data) => {
        if (data !== undefined) {
          const res = data["features"];
          setGeoJson({
            ...geoJson,
            features: [...geoJson.features, ...res],
          });
          setViewport({ ...viewport, zoom: 2.5 });
          onClose("true");
        } else {
          onClose("true");
          setloadingData(false);
        }
      });
    setloadingData(false);
    onClose("true");
  };
  const browseFiles = () => {
    $(".MuiDropzoneArea-root").trigger("click");
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle
        className={classes.dialogTitleContainer}
        id="simple-dialog-title"
      >
        Upload a CSV of Lat / Lon Data...
        <img
          onClick={handleClose}
          src="close.png"
          className={"closeImg"}
          alt=""
        />
      </DialogTitle>

      <DropzoneArea
        dropzoneText={"Drag a CSV file here..."}
        filesLimit={1}
        showFileNamesInPreview={true}
        acceptedFiles={[".csv, application/vnd.ms-excel, text/csv"]}
        showPreviews={true}
        maxFileSize={5000000}
        dropzoneClass={classes.dropZone}
        onChange={(event) => {
          handleChange(event);
        }}
      />
      <div className={classes.btnContainer}>
        <a
          href={"http://localhost:3000/geodata.csv"}
          style={{
            textDecoration: "none",
            fontSize: "0.8374rem",
            lineHeight: "16px",
            color: "#1C1C1C",
          }}
        >
          Download CSV template
        </a>
        <div>
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            className={classes.btn}
            style={{ marginRight: "16px" }}
            onClick={browseFiles}
          >
            Browse Files
          </Button>
          {loadingData === false && (
            <Button
              disableElevation
              disabled={noFileSelectes}
              color="secondary"
              variant="contained"
              className={classes.btn}
              onClick={() => {
                handleCloseonContineue();
              }}
            >
              Select &amp; Upload
            </Button>
          )}
          {loadingData === true && (
            <Button
              disableElevation
              disabled={!noFileSelectes}
              color="secondary"
              variant="contained"
              className={classes.btn}
            >
              <img src="/loading.gif" width="20" height="20" alt="" />
              &nbsp;&nbsp;&nbsp;Loading........
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
}
//eslint-disable-next-line
export default function CsvPopup(props: CsvPropsType) {
  const classes = useCsvPopupStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  //eslint-disable-next-line
  const handleClose = (value: string) => {
    setOpen(false);
  };

  return (
    <span>
      <Typography className={classes.links} style={{ display: "inline" }}>
        <span
          style={{
            color: "#1E75C3",
            textDecoration: "underline",
            fontSize: "10px",
            cursor: "pointer",
          }}
          onClick={handleClickOpen}
        >
          Upload CSV
        </span>
        &nbsp;{" "}
        <UploadIcon style={{ fontSize: "0.6875rem", marginBottom: "-1px" }} />
        &nbsp; to populate co-ordinates
      </Typography>

      <SimpleDialog open={open} onClose={handleClose} />
    </span>
  );
}

import React, { useContext, useEffect } from "react";
import { LocationSelectContext } from "../context/LocationSelectPageContext";
import { IconButton, TextField } from "@material-ui/core";
import { SnackbarContext } from "../context/SnackbarContext";
import {
  useLatLonTextFieldStyle,
  LatLonTextFieldPropsType,
} from "../Pages/LocationSelectPage/useStyles";

const LatLonTextField = (props: LatLonTextFieldPropsType) => {
  const classes = useLatLonTextFieldStyle(props);

  const {
    snackbarState: { setSnackbar },
  } = useContext(SnackbarContext);

  const {
    value1,
    value2,
    icon,
    onIconClick,
    name1,
    name2,
    onChange,
    isReadOnly,
    firstTitle,
    secondTitle,
  } = props;
  const { selectedSearchTypeState } = useContext(LocationSelectContext);
  const { selectedSearchType } = selectedSearchTypeState;
  const latRef = React.useRef<HTMLInputElement>();

  useEffect(() => {
    selectedSearchType === "coordinates" && latRef.current?.focus();
  }, [selectedSearchType]);

  return (
    <div
      className={classes.container}
      style={{ pointerEvents: isReadOnly ? "none" : "auto" }}
    >
      <div className={classes.labelContainer}>
        <label className={classes.labels} htmlFor="lat">
          {firstTitle} :
        </label>
      </div>
      <TextField
        //  name={
        //   secondTitle.toLowerCase() === "long"
        //     ? secondTitle.toLowerCase().replace(/g/g, "")
        //     : secondTitle.toLowerCase()
        // }
        // name={firstTitle.toLowerCase()}
        name={name1}
        inputRef={latRef}
        fullWidth
        id="lon"
        value={value1}
        variant="outlined"
        className={classes.inputField}
        inputProps={{
          style: {
            height: "32px",
            padding: "0 14px",
          },
        }}
        type="number"
        onChange={onChange}
      />
      <div className={classes.labelContainer} style={{ borderLeft: "none" }}>
        <label className={classes.labels} htmlFor="lat">
          {secondTitle} :
        </label>
      </div>
      <TextField
        //  name={firstTitle.toLowerCase()}
        //  name={
        //   secondTitle.toLowerCase() === "long"
        //     ? secondTitle.toLowerCase().replace(/g/g, "")
        //     : secondTitle.toLowerCase()
        // }
        name={name2}
        fullWidth
        id="lati"
        value={value2}
        variant="outlined"
        className={classes.inputField}
        inputProps={{
          style: {
            height: "32px",
            padding: "0 14px",
          },
        }}
        type="number"
        onChange={onChange}
      />
      {icon && (
        <div
          className={classes.labelContainer}
          style={{
            width: "32px",
            borderLeft: "none",

            borderRight: "1.21806px solid #BEBEBE",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            // background: name === "mainField" ? "" : "#ADE5F7",
          }}
          onClick={
            value1 && value2
              ? onIconClick
              : () =>
                  setSnackbar({
                    open: true,
                    color: "warning",
                    message: "Warning: fields can't be left empty",
                  })
          }
        >
          <IconButton>{icon}</IconButton>
        </div>
      )}
    </div>
  );
};

export default LatLonTextField;

import React, { useState } from "react";

import * as Msal from "msal";
import { useNavigation } from "react-navi";
import "./Login.scss";

import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";
import SignIn from "./SignIn";
import worldMap from "../../../images/world.svg";
import { AZURE_AUTH_CONFIG, BASE_API_URL } from "../../../kliima.env";


const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      width: "100%",
      height: "100%",
      minHeight: "100vh",
    },
    backgroundmap: {
      width: "100%",
      height: "100vh",

      backgroundColor: "#353535",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    nav: {
      width: "100%",
      height: "40px",
      background: "#1C1C1C",
    },
    avatar: {
      background: "rgb(111, 244, 234)",
      color: "#000",
      width: "18.57px",
      height: "18.57px",
      fontSize: "0.625rem",
      fontWeight: "bold",
    },
  });

type ClassKey = "root" | "backgroundmap" | "nav" | "avatar";
type PropsType = WithStyles<ClassKey>;

export const DATA_TOOLKIT_TOKEN = "data_token_tookit";

const Login: React.FC<PropsType> = (props: PropsType) => {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("deauth");

  const navigation = useNavigation();
  //eslint-disable-next-line
  const [login, setLogin] = useState({ account: { name: "" } });

  const msalConfig = {
    auth: AZURE_AUTH_CONFIG.auth,
  };

  const loginRequest = {
    scopes: ["openid", "profile", "User.Read"],
  };

  const myMSALObj = new Msal.UserAgentApplication(msalConfig);

  const signIn = () => {
    myMSALObj
      .loginPopup(loginRequest)
      .then((loginResponse) => {
        setLogin(loginResponse);
        storeProfile({
          userName: loginResponse.account.userName,
          name: loginResponse.account.name,
          email: loginResponse.account.userName,
          token: loginResponse.account.idToken.aud,
        });
        // if the user is already logged in you can acquire a token
        if (myMSALObj.getAccount()) {
          var tokenRequest = {
            scopes: ["user.read"],
          };
          myMSALObj
            .acquireTokenSilent(tokenRequest)
            .then((response) => {
              // get access token from response if user has allowed the access
              if (response.accessToken) {
                getAccessToken(response.accessToken);
              }

              //console.log(response.accessToken);
            })
            .catch((err) => {
              if (err.name === "InteractionRequiredAuthError") {
                return myMSALObj
                  .acquireTokenPopup(tokenRequest)
                  .then((response) => {
                    // get access token from response if user is login first time
                    if (response.accessToken) {
                      getAccessToken(response.accessToken);
                    }
                    // console.log(response.accessToken);
                  })
                  .catch((err) => {
                    // handle error
                  });
              }
            });
        } else {
          // user is not logged in, you will need to log them in to acquire a token
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // navigation.navigate("/");
      });
  };

  const signOut = () => {
    myMSALObj.logout();
  };
 
  if (myParam === "true") {
    signOut();
  }

  const ssoLogin = () => {
    signIn();
  };

  const getAccessToken = (access) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: access }),
    };
    fetch(`${BASE_API_URL}/auth/login-sso`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        storeToken(data.token);
        navigation.setContext({ token: data.token });
        navigation.navigate("/");
      });
  };

  return (
    <>
      <div id={"backgroundmap"} className={props.classes.backgroundmap}>
        <img
          src={worldMap}
          style={{ width: "100%", height: "100%" }}
          alt="world-map-svg"
        />
        <SignIn signedIn={ssoLogin} />
      </div>
    </>
  );
};

export default withStyles(styles)(Login);

const storeToken = (token: string) => {
  localStorage.setItem(DATA_TOOLKIT_TOKEN, token);
};
const storeProfile = (profile: object) => {
  localStorage.setItem("KLIIMA_PROFILE", JSON.stringify(profile));
};

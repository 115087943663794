import React, { createContext, useState } from "react";
import { LocationSelectPageContextType } from "../../../types/LocationSelectPageContextTypes";

export const LocationSelectContext = createContext<LocationSelectPageContextType>(
  {} as LocationSelectPageContextType
);

type PropsType = {
  children: React.ReactNode;
};

export const LocationSelectPageContextProvider = ({ children }: PropsType) => {
  const [
    isWelcomeToTheArupModalOpen,
    setIsWelcomeToTheArupModalOpen,
  ] = useState<boolean>(true);

  const [
    isSelectLocationCardVisible,
    setIsSelectLocationCardVisible,
  ] = useState(true);

  const [selectedSearchType, setSelectedSearchType] = useState<string>("");

  const [
    isVariableSelectionModalOpen,
    setIsVariableSelectionModalOpen,
  ] = useState<boolean>(false);

  const [
    isSelectClimateVariableCardVisible,
    setIsSelectClimateVariableCardVisible,
  ] = useState<boolean>(false);

  const [
    selectedClimateVariable,
    setSelectedClimateVariable,
  ] = useState<string>("");

  const [
    isWindRelatedVariablesModalOpen,
    setIsWindRelatedVariablesModalOpen,
  ] = useState<boolean>(false);

  const [
    isConfigureDatasetVisible,
    setIsConfigureDatasetVisible,
  ] = useState<boolean>(false);

  const [
    isDatasetSelectionModalOpen,
    setIsDatasetSelectionModalOpen,
  ] = useState<boolean>(false);

  const [
    isClimateDataHistoryModalOpen,
    setIsClimateDataHistoryModalOpen,
  ] = useState<boolean>(false);

  const [
    isProjectDescriptionModalOpen,
    setIsProjectDescriptionModalOpen,
  ] = useState(false);

  const [isClimateDatasetModalOpen, setIsClimateDatasetModalOpen] = useState(
    false
  );

  const [isTermsOfUseModalOpen, setIsTermsOfUseModalOpen] = useState(false);

  const [selectedDataset, setSelectedDataset] = useState<string>("");

  const [
    selectedClimateVariableSource,
    setSelectedClimateVariableSource,
  ] = useState<string>("");

  const [isProjectDataDpdOpen, setIsProjectDataDpdOpen] = useState(false);

  const [isRegionDpdOpen, setIsRegionDpdOpen] = useState(false);

  const [
    doesEachFieldOfConfigDatasetCardHasValue,
    setDoesEachFieldOfConfigDatasetCardHasValue,
  ] = useState(false);

  const [lessThanMoreThanValues, setLessThanMoreThanValues] = useState({
    thresholdValueLessThan: 0,
    thresholdValueMoreThan: 0,
  });

  const [isOtherToolCardVisibile, setIsOtherToolCardVisibile] = useState(true);

  const [
    shouldFocusOnHistoryProjectionDpd,
    setShouldFocusOnHistoryProjectionDpd,
  ] = useState(false);

  const [
    isHistoryViewDetailsDialogOpen,
    setIsHistoryViewDetailsDialogOpen,
  ] = useState(false);

  const [retriveData, setRetriveData] = useState({
    region: {
      name: "Europe",
      lat: [53.4563],
      lon: [2.3456],
    },
    country: "london",
    project_name: "project",
    job_code: "37291",
    primary_key: 12,
    threshold_value_less_than: 0,
    threshold_value_more_than: 0,
    main_variable: "Temerature",
    variable: ["dummy"],
    datasource: "CORDEX",
    from_date: 2006,
    to_date: 2010,
  });

  const closeAllModalsAndPopups = () => {
    setIsWelcomeToTheArupModalOpen(false);
    setIsVariableSelectionModalOpen(false);
    setIsSelectClimateVariableCardVisible(false);
    setIsWindRelatedVariablesModalOpen(false);
    setIsConfigureDatasetVisible(false);
    setIsDatasetSelectionModalOpen(false);
    setIsClimateDataHistoryModalOpen(false);
    setIsProjectDescriptionModalOpen(false);
    setIsClimateDatasetModalOpen(false);
    setIsOtherToolCardVisibile(false);
    setIsSelectLocationCardVisible(false);
    setIsHistoryViewDetailsDialogOpen(false);
    setIsTermsOfUseModalOpen(false);
  };

  return (
    <LocationSelectContext.Provider
      value={{
        retrivableData: [retriveData, setRetriveData],

        welcomeToTheArupModalState: {
          isWelcomeToTheArupModalOpen,
          setIsWelcomeToTheArupModalOpen,
        },

        selectLocationCardVisibleState: {
          isSelectLocationCardVisible,
          setIsSelectLocationCardVisible,
        },

        selectedSearchTypeState: {
          selectedSearchType,
          setSelectedSearchType,
        },

        variableSelectionModalState: {
          isVariableSelectionModalOpen,
          setIsVariableSelectionModalOpen,
        },

        selectClimateVariableCardVisibleState: {
          isSelectClimateVariableCardVisible,
          setIsSelectClimateVariableCardVisible,
        },

        selectedClimateVariableState: {
          selectedClimateVariable,
          setSelectedClimateVariable,
        },

        windRelatedVariablesModalState: {
          isWindRelatedVariablesModalOpen,
          setIsWindRelatedVariablesModalOpen,
        },

        configureDatasetVisibleState: {
          isConfigureDatasetVisible,
          setIsConfigureDatasetVisible,
        },

        datasetSelectionModalState: {
          isDatasetSelectionModalOpen,
          setIsDatasetSelectionModalOpen,
        },

        selectedDatasetState: {
          selectedDataset,
          setSelectedDataset,
        },

        selectedClimateVariableSourceState: {
          selectedClimateVariableSource,
          setSelectedClimateVariableSource,
        },

        climateDataHistoryModalState: {
          isClimateDataHistoryModalOpen,
          setIsClimateDataHistoryModalOpen,
        },

        projectDescriptionModalState: {
          isProjectDescriptionModalOpen,
          setIsProjectDescriptionModalOpen,
        },

        projectDataDpdOpenState: {
          isProjectDataDpdOpen,
          setIsProjectDataDpdOpen,
        },

        regionDpdOpenState: {
          isRegionDpdOpen,
          setIsRegionDpdOpen,
        },

        climateDatasetModalState: {
          isClimateDatasetModalOpen,
          setIsClimateDatasetModalOpen,
        },

        lessThanMoreThanValuesState: {
          lessThanMoreThanValues,
          setLessThanMoreThanValues,
        },

        otherToolCardVisibileState: {
          isOtherToolCardVisibile,
          setIsOtherToolCardVisibile,
        },

        focusOnHistoryProjectionDpdState: {
          shouldFocusOnHistoryProjectionDpd,
          setShouldFocusOnHistoryProjectionDpd,
        },

        historyDialogState: {
          isHistoryViewDetailsDialogOpen,
          setIsHistoryViewDetailsDialogOpen,
        },

        doesEachFieldOfConfigDatasetCardHasValueState: {
          doesEachFieldOfConfigDatasetCardHasValue,
          setDoesEachFieldOfConfigDatasetCardHasValue,
        },

        termsOfUserModalState: {
          isTermsOfUseModalOpen,
          setIsTermsOfUseModalOpen,
        },

        handlers: {
          closeAllModalsAndPopups,
        },
      }}
    >
      {children}
    </LocationSelectContext.Provider>
  );
};

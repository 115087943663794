import React, { useContext, useState } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import CustomModal from "../../../partials/CustomModal";
import DataSetCard from "./DataSetCard";

import windDataSetPng from "../../../../../images/windDataSet.png";
import { LocationSelectContext } from "../../../context/LocationSelectPageContext";
import Pagination from "../../../partials/Pagination";

import {
  temperatureRelatedSources,
  windRelatedSources,
  humdityRelatedSources,
  snowRelatedSources,
  marineRelatedDataSources,
  precipitationRelatedDataSources,
  SourceDataType,
} from "./data";

const useStyles = makeStyles({
  btn: {
    width: "8.7975rem",
    fontSize: "0.8374rem",
    lineHeight: "16px",
    color: " #1C1C1C",
  },
});

type PropsType = {
  open: boolean;
  closeModalHandler: () => void;
};

const WindRelatedVariablesModal = (props: PropsType) => {
  const classes = useStyles();

  const { open, closeModalHandler } = props;

  const {
    windRelatedVariablesModalState,
    configureDatasetVisibleState,
    selectedClimateVariableSourceState,
    selectedClimateVariableState,
    variableSelectionModalState,
    climateDatasetModalState,
  } = useContext(LocationSelectContext);

  const { setIsWindRelatedVariablesModalOpen } = windRelatedVariablesModalState;

  const { setIsConfigureDatasetVisible } = configureDatasetVisibleState;

  const { setIsVariableSelectionModalOpen } = variableSelectionModalState;

  const { setIsClimateDatasetModalOpen } = climateDatasetModalState;

  const {
    selectedClimateVariableSource,
    setSelectedClimateVariableSource,
  } = selectedClimateVariableSourceState;

  const { selectedClimateVariable } = selectedClimateVariableState;

  let data = [] as SourceDataType[];

  switch (selectedClimateVariable) {
    case "Temperature":
      data = temperatureRelatedSources;
      break;
    case "Wind":
      data = windRelatedSources;
      break;
    case "Humidity":
      data = humdityRelatedSources;
      break;
    case "Snow":
      data = snowRelatedSources;
      break;
    case "Marine":
      data = marineRelatedDataSources;
      break;
    case "Precipitation":
      data = precipitationRelatedDataSources;
      break;
    default:
      break;
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <CustomModal
      title={`${selectedClimateVariable} Related Sources`}
      isOpen={open}
      onClick={closeModalHandler}
      paragraph={
        <p
          style={
            {
              // marginTop: "-3rem"
            }
          }
        >
          Next, select a climate variable parameter to display...
        </p>
      }
    >
      <>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {currentItems.map((item, i) => (
            <DataSetCard
              onSelect={() => {
                setSelectedClimateVariableSource(item.title);
              }}
              title={item.title}
              description={item.description}
              unit={item.unit}
              selected={item.title === selectedClimateVariableSource}
              key={i}
              imgPath={windDataSetPng}
            />
          ))}
        </div>
        <Grid
          item
          container
          justify="center"
          style={{ marginBottom: "2.25rem" }}
        >
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => {
              setIsWindRelatedVariablesModalOpen(false);
              setIsVariableSelectionModalOpen(true);
            }}
          >
            Go Back
          </Button>
          <Button
            className={classes.btn}
            variant="contained"
            color="secondary"
            disableElevation
            style={{ marginLeft: "3.625rem" }}
            onClick={() => {
              setIsWindRelatedVariablesModalOpen(false);
              setIsConfigureDatasetVisible(true);

              setIsClimateDatasetModalOpen(true);
            }}
            disabled={!selectedClimateVariableSource}
          >
            Continue
          </Button>
        </Grid>
      </>

      <Grid container justify="center">
        {data.length > 6 && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={data.length}
            paginate={paginate}
            currentPageNumber={currentPage}
          />
        )}
      </Grid>
    </CustomModal>
  );
};

export default WindRelatedVariablesModal;

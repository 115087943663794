import React, { useContext,  useState } from "react";

import CustomModal from "../../../partials/CustomModal";
import ReviewDatasetCard from "./ReviewDatasetCard";

import worldMapSvg from "../../../../../images/worldMap.svg";
import WindIcon from "../../../Icons/WindIcon";
import DatasetConfigurationIcon from "../../../Icons/DatasetConfigurationIcon";
import datasetLogo from "../../../../../images/datasetLogo.svg";
import ServicesApi from "../../../../api/services";
import {
  useProjectDescriptionModalStyles,
  ProjectDescriptionModalPropTypes,
} from "./useStyles";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { LocationSelectContext } from "../../../context/LocationSelectPageContext";
import { SnackbarContext } from "../../../context/SnackbarContext";
import { RegionContext } from "../../../context/RegionContext";

const ProjectDescriptionModal = (props: ProjectDescriptionModalPropTypes) => {
  const classes = useProjectDescriptionModalStyles();

  const { open, closeModalHandler } = props;
  const { geoJSON } = useContext(RegionContext);
  //eslint-disable-next-line
  const [geoJson, setGeoJson] = geoJSON;

  const {
    snackbarState: { setSnackbar },
  } = useContext(SnackbarContext);

  const {
    selectedClimateVariableState,
    selectedDatasetState,

    retrivableData,
    climateDataHistoryModalState,
    handlers,
  } = useContext(LocationSelectContext);

  const [retriveData] = retrivableData;

  const { selectedDataset } = selectedDatasetState;

  const { selectedClimateVariable } = selectedClimateVariableState;

  const { setIsClimateDataHistoryModalOpen } = climateDataHistoryModalState;

  const [projectName, setProjectName] = useState("");

  const [jobCode, setJobCode] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isJobeCodeValid, setIsJobeCodeValid] = useState(false);

  const { closeAllModalsAndPopups } = handlers;

  const data: Array<{
    title: string;
    description: string | React.ReactNode;
    icon?: React.ReactNode;
    iconPath?: string;
  }> = [
    {
      title: "Location:",
      description: `${retriveData.region}, ${retriveData.country}`,
      iconPath: worldMapSvg,
    },
    {
      title: "Climate Variable:",
      description: selectedClimateVariable,
      icon: (
        <WindIcon
          style={{
            color: "white",

            fontSize: "2.5rem",
          }}
        />
      ),
    },
    {
      title: "Dataset:",
      description: selectedDataset,
      iconPath: datasetLogo,
    },
    {
      title: "Dataset Configuration:",
      description: (
        <span>
          Historical data {retriveData.projection}
          <br />
          Projected Data {retriveData.projectedData}
        </span>
      ),
      icon: (
        <DatasetConfigurationIcon
          style={{
            color: "white",

            fontSize: "2.5rem",
          }}
        />
      ),
    },
  ];

  const jobCodeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filteredValue = event.target.value
      .replace(/[^0-9-]/g, "")
      .replace(/^(\d{2})(\d)/, "$1-$2");

    setIsJobeCodeValid(false);

    setJobCode(filteredValue);
  };

  const shouldRetriveDataButtonEnabled = () => {
    if (projectName === "" || jobCode === "" || isLoading) {
      return true;
    }
    return false;
  };

  const onRetriveData = () => {
    const regex = /[0-9]{2,2}-[0-9]/gm;

    const isValid = regex.test(jobCode);

    if (!isValid) {
      setIsJobeCodeValid(true);
      return;
    } else {
      setIsJobeCodeValid(false);
    }

    let latSearch: Array<number> = [];
    let lonSearch: Array<number> = [];
    //eslint-disable-next-line
    const getAllLatLon = geoJson.features.map((item, index) => {
      if (index > 0) {
        latSearch.push(+geoJson.features[index].geometry.coordinates[0]);
        lonSearch.push(+geoJson.features[index].geometry.coordinates[1]);
      }
    });

    const refinedData = {
      region: {
        name: retriveData.region,

        lat: latSearch,
        lon: lonSearch,
      },
      country: retriveData.country,
      project_name: projectName,
      job_code: jobCode,
      primary_key: 12,
      threshold_value_less_than: retriveData.thresholdValueLessThan,
      threshold_value_more_than: retriveData.thresholdValueMoreThan,
      main_variable: retriveData.climateVariable,

      variable: [retriveData.climateVariableSource],

      datasource: retriveData.dataset,
      historical_from_year: parseInt(retriveData.historical_from_year),
      historical_to_year: parseInt(retriveData.historical_to_year),
      projectional_from_year: parseInt(retriveData.projectional_from_year),
      projectional_to_year: parseInt(retriveData.projectional_to_year),
    };

    const sendRetreveData = async () => {
      setIsLoading(true);

      const { data } = await ServicesApi.post<[]>(
        "submit-request",
        refinedData
      );

      return data;
    };
    sendRetreveData()
      .catch(() => setIsLoading(false))
      .then((data) => {
        setIsLoading(false);
        if (data !== undefined) {
          setSnackbar({
            color: "success",
            open: true,
            message: 'Task added successfully wait for email response!!!"',
          });
          closeAllModalsAndPopups();
          setIsClimateDataHistoryModalOpen(true);
        } else {
          setSnackbar({
            color: "error",
            open: true,
            message: 'Something went wrong"',
          });
        }
      });
  };

  const reviewDatasetCards = data.map((item, i) => (
    <div key={i} style={{ width: 200 }}>
      <ReviewDatasetCard
        title={item.title}
        description={item.description}
        icon={item.icon}
        iconPath={item.iconPath}
      />
    </div>
  ));

  return (
    <CustomModal
      title="Review dataset selections..."
      headingFontSize="1.5rem"
      isOpen={open}
      onClick={closeModalHandler}
    >
      <div className={classes.iconsContainer}>{reviewDatasetCards}</div>

      <div className={classes.contentContainer}>
        <Typography>
          Please enter a title for this item of data retrieval. This will be
          added to your prohect history page enabling you to find and manage the
          data you have requested.
        </Typography>

        <div className={classes.textFieldContainer}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter Project Name"
            className={classes.textField}
            inputProps={{
              style: {
                height: "32px",
                padding: "0 14px",
              },
            }}
            onChange={(e) => setProjectName(e.target.value)}
            value={projectName}
          />

          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter Job Number"
            className={classes.textField}
            error={isJobeCodeValid}
            inputProps={{
              style: {
                height: "32px",
                padding: "0 14px",
              },
            }}
            style={{ marginTop: "17px", marginBottom: "17px" }}
            onChange={jobCodeHandler}
            value={jobCode}
          />
        </div>

        <Typography>
          Please enter the Job Number for the project or proposal on which you
          are using Kliima. This is for our records on where and how in Arup the
          tool is being used. There is currently no direct charge made to your
          Job Number but we expect your clients to be charged appropriately for
          the value that Kliima provides. Please contact the team if you need
          guidance on pricing.
          <br />
          <br />
          <em>
            <b>
              Please note we will start charging for use of the tool in 2022 to
              fund ongoing development and maintenance.
            </b>
          </em>
          <br />
          <br />
          Once you have checked and are happy with the selections you have made,
          click ‘Retrieve Data’ to proceed. Depending on the nature of your
          request, this can take up to several hours to process. Next, you will
          be taken to your project history page which allows you to manage the
          data you have requested and view the progress and status of previous
          requests.
        </Typography>
      </div>

      <Grid item container justify="center" style={{ marginTop: "1.5rem" }}>
        <Button
          onClick={() => {
            onRetriveData();
          }}
          className={classes.btn}
          variant="contained"
          disableElevation
          disabled={shouldRetriveDataButtonEnabled()}
        >
          {isLoading ? (
            <CircularProgress color="primary" size={20} />
          ) : (
            "Retrieve Data"
          )}
        </Button>
      </Grid>
    </CustomModal>
  );
};

export default ProjectDescriptionModal;

import React from "react";
import { Box, Button, Typography } from "@material-ui/core";

import weatherShiftLogo from "../../../../images/weatherShiftLogo.png";
import CollapsibleCard from "../../partials/CollapsibleCard";
import { CSSTransition } from "react-transition-group";

type PropsType = {
  isOtherToolCardVisibile: boolean;
};

function OtherToolCard(props: PropsType) {
  const { isOtherToolCardVisibile } = props;

  return (
    <CSSTransition
      in={isOtherToolCardVisibile}
      timeout={300}
      classNames="slide-right-animation"
      unmountOnExit
    >
      <div style={{ marginTop: "auto" }}>
        <CollapsibleCard title="Other Arup Climate Tools...">
          <img
            src={weatherShiftLogo}
            style={{ height: "1.8125rem", marginBottom: "1.875rem" }}
            alt="logo"
          />
          <Typography style={{ fontSize: "0.875rem" }}>
            Buildings and infrastructure built today will experience
            significantly different weather patterns over the course of the 21st
            century due to the impact of climate change.
            <br />
            <br />
            The WeatherShift&trade; tool uses data from global climate change
            modeling to produce EPW weather files adjusted for changing climate
            conditions. (EPW files contain hourly values of key weather
            variables for a typical year and are intended to be used for
            simulating building energy requirements.) The projected data can be
            viewed for three future time periods based on the emission scenario
            selected to the left.
          </Typography>
          <Box
            display="flex"
            width="100%"
            justifyContent="center"
            mt="1.875rem"
            mb="1.25rem"
          >
            <Button
              href=" https://www.weathershift.com"
              target="_blank"
              disableElevation
              variant="contained"
              color="secondary"
            >
              Launch Tool
            </Button>
          </Box>
        </CollapsibleCard>
      </div>
    </CSSTransition>
  );
}

export default OtherToolCard;

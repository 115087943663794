/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { RegionContext } from "../../../context/RegionContext";
import ServicesApi from "../../../../api/services";
import { TableData } from "../../../../../types/types";
import moment from "moment";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const TABLE_ROW_HEIGHT = "2rem";

const useStyles = makeStyles({
  contentContainer: {
    "&::-webkit-scrollbar-thumb": {
      width: 3,
      backgroundColor: "#000",
      borderRadius: "10px",
    },

    "&::-webkit-scrollbar": {
      width: 3,
    },
  },

  projectName: {
    fontSize: "18px",
    lineHeight: "21px",
    color: "#1C1C1C",
    textTransform: "uppercase",
  },

  bodyText: {
    fontSize: "1rem",
    lineHeight: "21px",
    display: "inline-block",
  },

  tableContainer: {
    "&  table": {
      width: "98%",
    },

    "&  table th, & table td": {
      fontSize: "0.75rem",
      lineHeight: "14px",
      color: "#1C1C1C",
      fontWeight: "normal",
    },

    "&  table, & th, & td": {
      border: "1px solid black",
      borderCollapse: "collapse",
      textAlign: "center",
    },

    "&  table  th, & table td": {
      padding: "5px 10px",
    },

    "& table  tr": {
      height: TABLE_ROW_HEIGHT,
    },
  },

  number: {
    fontSize: "10px",
    lineHeight: "12px",

    color: "#9B9B9B",
    position: "absolute",
    top: 7,
    left: 4,
  },
});

type PropsType = {
  selectedHistoryCardId?: number;
};

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </Box>
  );
};

function Output(props: PropsType) {
  const { selectedHistoryCardId } = props;

  const classes = useStyles();
  const { mapViewPort, outMMarker, historyTab } = useContext(RegionContext);
  const [historyTable, setHistoryTable] = historyTab;
  const tableData = historyTable;
  const [outputMarker, setOutputMarker] = outMMarker;
  const [viewport, setViewport] = mapViewPort;
  const [busy, setBusy] = useState<null | "error" | "loading">(null);

  useEffect(() => {
    setBusy("loading");
    const fetchTableData = async () => {
      try {
        const { data } = await ServicesApi.get<TableData>("/table-detail", {
          params: { id: selectedHistoryCardId },
        });
        setBusy(null);

        data && setHistoryTable(data);
      } catch (err) {
        console.log(err);
        setBusy("error");
      }
    };

    selectedHistoryCardId && fetchTableData();
  }, [selectedHistoryCardId]);

  const colorMarker = (e, i, lon, lat, itemData) => {
    setOutputMarker({ visible: true, selected: i });
    setViewport({
      ...viewport,
      zoom: 3,
      latitude: lon,
      longitude: lat,
    });
  };

  // converting number into
  const convertStrIntoNum = (val: string) => {
    const output = parseFloat(Number(val).toFixed(3));
    return isNaN(output) ? "N/A" : output;
  };

  const renderBusyContent = () => {
    if (busy === "loading") {
      return (
        <Wrapper>
          <Typography variant="h5">Getting Data </Typography>{" "}
          <CircularProgress
            style={{ marginLeft: "1rem" }}
            color="secondary"
            size={24}
          />
        </Wrapper>
      );
    }
    if (busy === "error") {
      return (
        <Wrapper>
          <Typography variant="h5" color="error">
            Something went wrong, Please try again{" "}
          </Typography>{" "}
          <ErrorOutlineIcon style={{ marginLeft: "1rem" }} color="error" />
        </Wrapper>
      );
    }
  };

  return (
    <Grid container style={{ marginTop: "2rem" }}>
      <Grid item xs={3}>
        <div
          style={{
            height: "288px",
            overflowY: "scroll",
            paddingRight: "2.5rem",
          }}
          className={classes.contentContainer}
          id="textToDownloaded"
        >
          <Typography variant="body1" className={classes.projectName}>
            {tableData.project_name}
          </Typography>

          <Typography component="span" className={classes.bodyText}>
            Job Number: {tableData.job_number}
            <br />
            Requested: {moment(tableData.requested_time).format("DD-MM-YYYY")}
            <br /> <br />
            Source: {tableData?.data_source?.toUpperCase()}
            <br />
            <br />
            Region: {tableData.region_name}
            <br />
            Country: {tableData.country_name}
            <br />
            Lat / Lon: {tableData.selected_latlon}
            <br />
            <br />
            Variable: {tableData.variable}
            <br />
            Historical Period: {tableData.historical_Period}
            <br />
            Future Period: {tableData.future_period}
            <br />
            <br />
            Models used:
            <br />
            {tableData?.modelsUsed?.map((item, i) => (
              <p key={i}>{item}</p>
            ))}
            <br />
          </Typography>
        </div>
      </Grid>
      <Grid item xs={9}>
        <Box className={classes.tableContainer} pl="2rem">
          {busy ? (
            renderBusyContent()
          ) : (
            <Box id="tableToDownloaded">
              <Box width="100%">
                <table style={{ width: "100%", borderBottom: "none" }}>
                  <thead>
                    <tr>
                      <th style={{ border: "none" }}>
                        {tableData.main_table_name}
                      </th>
                    </tr>
                  </thead>
                </table>
              </Box>

              <Box display="flex">
                {/* mapping location table  */}
                <table style={{ maxWidth: "10.125rem" }}>
                  <thead>
                    <tr>
                      <th
                        colSpan={
                          tableData.table_structure_location?.header?.length
                        }
                      >
                        {tableData?.table_structure_location?.name}
                      </th>
                    </tr>

                    <tr>
                      {tableData.table_structure_location?.header?.map(
                        (e, i) => (
                          <th key={i}>{e}</th>
                        )
                      )}
                    </tr>
                  </thead>

                  <tbody>
                    {tableData.table_structure_location?.row?.map((item, i) => (
                      <tr
                        key={i}
                        style={{ position: "relative" }}
                        className={`history-row ${
                          i === outputMarker.selected ? "active" : ""
                        }`}
                      >
                        <td>
                          <span
                            onClick={(e) => {
                              colorMarker(
                                e,
                                i,
                                item.value[0],
                                item.value[1],
                                item
                              );
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <span className={classes.number}>{i + 1}</span>

                            {Number(item.value[0]).toFixed(3) +
                              " / " +
                              Number(item.value[1]).toFixed(3)}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* mapping RCPs table  */}
                {tableData.table_data?.map((data, i) => (
                  <table key={i}>
                    <thead>
                      <tr>
                        <td colSpan={data.table_structure?.headerName?.length}>
                          {data.table_structure.name}
                        </td>
                      </tr>

                      <tr>
                        {data.table_structure?.headerName?.map((e, indx) => (
                          <td key={indx}>{e}</td>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {data.table_structure?.row?.map((e, indx) => (
                        <tr
                          key={indx}
                          className={`history-row ${
                            indx === outputMarker.selected ? "active" : ""
                          }`}
                        >
                          <td>
                            {convertStrIntoNum(e.min_model_result_RCP_one)}
                          </td>

                          <td>
                            {convertStrIntoNum(e.mean_model_result_RCP_one)}
                          </td>
                          <td>
                            {convertStrIntoNum(e.median_model_result_RCP_one)}
                          </td>
                          <td>
                            {convertStrIntoNum(e.max_model_result_RCP_one)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default Output;

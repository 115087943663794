import React from "react";
import { Typography, IconButton, useMediaQuery } from "@material-ui/core";
import CloseIcon from "../Icons/CloseIcon";

import { CSSTransition } from "react-transition-group";

import { CustomModalPropTypes, useCustomModalStyles } from "./useStyles";

const CustomModal = (props: CustomModalPropTypes) => {
  const classes = useCustomModalStyles(props);

  const {
    isOpen,
    title,
    subTitle,
    children,
    onClick,
    paragraph,
    headingMaxWidth,
    headingFontSize,
    headingBottomText,
    justifyContent,
  } = props;

  const isSmallScreen = useMediaQuery("(max-width:955px)");

  return (
    <CSSTransition
      in={isOpen}
      timeout={300}
      classNames="slide-left-animation"
      unmountOnExit
    >
      <section
        className={classes.mainContainer}
        style={{ overflowY: isSmallScreen ? "scroll" : "auto" }}
      >
        <IconButton
          style={{ position: "absolute" }}
          onClick={onClick}
          aria-label="delete"
          className={classes.icon}
        >
          <CloseIcon style={{ fontSize: "16px", cursor: "pointer" }} />
        </IconButton>

        <div>
          <Typography
            variant="h4"
            className={classes.mainHeading}
            style={{ maxWidth: headingMaxWidth, fontSize: headingFontSize }}
          >
            {title}
            <span className={classes.mainHeadingSubHeading}>
              {headingBottomText}
            </span>
          </Typography>
          {subTitle && (
            <Typography
              variant="h4"
              style={{
                //  marginTop: "-2.1875rem",
                marginBottom: "2.5rem",
              }}
              className={classes.subHeading}
            >
              {subTitle}
            </Typography>
          )}

          {paragraph && (
            <Typography
              variant="subtitle2"
              className={classes.paragraph}
              style={{ fontWeight: "normal" }}
            >
              {paragraph}
            </Typography>
          )}
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: justifyContent ? justifyContent : "space-between",
          }}
        >
          {children}
        </div>
      </section>
    </CSSTransition>
  );
};

export default CustomModal;

import React from "react";

import { Grid, Typography } from "@material-ui/core";

import CustomModal from "../../../partials/CustomModal";
import {
  TermsOfUseModalPropTypes,
  useTermsOfUseModalStyles,
} from "./useStyles";
import searchRegionSvg from "../../../../../images/termsImg.svg";

function TermsOfUseModal(props: TermsOfUseModalPropTypes) {
  const { open, closeModalHandler } = props;

  const classes = useTermsOfUseModalStyles();

  return (
    <CustomModal
      width="100%"
      title="Terms of Use"
      headingFontSize="2.25rem"
      subTitle="Be aware of how you use data on projects"
      isOpen={open}
      onClick={closeModalHandler}
    >
      <Grid container>
        <Grid item xs={4}>
          <div className={classes.imgContainer}>
            <img
              src={searchRegionSvg}
              alt="terms of use"
              style={{ height: "10.4812rem", marginBottom: "12px" }}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={8}
          style={{
            marginTop: "-150px",
            maxHeight: "100%",
            overflowY: "scroll",
          }}
        >
          <Typography component="span" variant="body1" className={classes.bodyText}>
            <b>
              {" "}
              The Kliima tool is for use by Arup employees only. Clients should
              not be given direct access to the tool.
            </b>
            <br />
            <br />
            Please be aware that the data used in Kliima is drawn from publicly
            available sources external to Arup. These are widely considered to
            be reputable sources but Arup has not checked or scrutinized the
            data. Further information about the data sources is available in the
            tool and from:
            <br />
            <br />
            CORDEX
            <br />
            <a
              href="https://cordex.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://cordex.org/
            </a>
            <br />
            <br />
            UKCP18
            <br />
            <a
              href="https://www.metoffice.gov.uk/research/approach/collaboration/ukcp"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.metoffice.gov.uk/research/approach/collaboration/ukcp
            </a>
            <br />
            <br />
            NOAA LOCA <br />
            <a
              href="http://loca.ucsd.edu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://loca.ucsd.edu/
            </a>
            <br />
            <br />
            CMIP5
            <br />
            <a
              href="https://www.wcrp-climate.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.wcrp-climate.org/
            </a>
            <br />
            <br />
            Before using the tool on your project,you should obtain your
            client’s informed written consent to do so. This means discussing
            with your client
            <br />
            <br />
            <ul>
              <li>What kliima does</li>
              <li>Where the data comes from</li>
            </ul>
            <br />
            <br />
            In short, climate model outputs can help consider possible future
            climate scenarios or outcomes, but they cannot guarantee that actual
            events will occur as modelled– no model that attempts to project the
            future can do so with certainty.
            <br />
            <br />
            Record your client’s agreement to use climate model projections in
            the can either be in the contract scope.
            <br />
            <br />
            <span style={{ fontSize: "1.4rem" }}>
              CONTRACT SCOPE: Future climate modelling
            </span>
            <br />
            <br />
            <em style={{ color: "#1C79B4" }}>
              We will use climate model outputs obtained from external sources
              including [state the source of the model outputs]. Such models can
              help consider possible future climate scenarios or outcomes, but
              no model that attempts to project the future can do so with
              certainty. Actual events may not occur as expected, and the
              differences may be material. As such, Arupcannot and does not make
              any representation or warranty, express or implied, regarding the
              accuracy or completeness of any such forward-looking advice, or
              any models, projections, forecasts, opinions or estimates, and
              Arup accepts no responsibility for the realisation or
              non-realisation of the same.
              <br />
              <br />
              Any advice, including forward-looking advice, is time-sensitive at
              the time of writing. Climate models are constantly updated and
              there may be material differences between climate models used at
              the time of writing and climate models generated later. We will
              not be under any obligation toupdate our work to address changes
              in facts or circumstances or modelling techniques that occur after
              the date of our deliverables, which might affect the contents of
              or any of the conclusions set out therein. <br />
              <br />I orf it is not possible to include the above in the
              contract scope,ensure you havea “paper trail” saved to the project
              file evidencing your client’s informed consentto the use of
              climate model outputs.
              <br />
              <br />
            </em>{" "}
            If any results from the toolKliima are used in providing your
            services/advice, include the following disclaimer in your
            deliverables in addition to your usual disclaimers. <br />
            <br />
            <span style={{ fontSize: "1.4rem" }}>
              DISCLAIMER: Future climate modelling
            </span>
            <br />
            <br />
            In preparing this [advice/report] we have used climate model outputs
            obtained from external sources including [state the source of the
            model outputs]. Such models can help consider possible future
            climate scenarios or outcomes, but no model that attempts to project
            the future can do so with certainty. Actual events may not occur as
            expected, and the differences may be material. As such, Arup cannot
            and does not make anyrepresentation or warranty, express or implied,
            regarding the accuracy or completeness of any such forward-looking
            advice, or any models, projections, forecasts, opinions or
            estimates, and Arup accepts no responsibility for the realisation or
            non-realisation of the same.
            <br />
            <br />
            <em style={{ color: "#1C79B4", textDecoration: "underline" }}>
              Any advice, including forward-looking advice, is time-sensitive at
              the time of writing. Climate models are constantly updated and
              there may be material differences between climate models used at
              the time of writing and climate models generated later. We will
              not be under any obligation toupdate our work to address changes
              in facts or circumstances or modelling techniques that occur after
              the date of our deliverables,whichmight affect the contents of or
              any of the conclusions set out herein
            </em>
          </Typography>
        </Grid>
      </Grid>
    </CustomModal>
  );
}

export default TermsOfUseModal;

import React, { useEffect, useState, useContext } from "react";

import Masonry from "react-masonry-css";

import {
  useClimateDataHistoryModalStyles,
  ClimateDataHistoryModalPropsType,
} from "./useStyles";
import ServicesApi from "../../../../api/services";

import CustomModal from "../../../partials/CustomModal";
// import Filter from "../../../partials/Filter";

import ClimateDataHistoryCard from "./ClimateDataHistoryCard";
import PlusIcon from "../../../Icons/PlusIcon";
import { Button } from "@material-ui/core";
import { History, DisplayData } from "../../../../../types/HistofyDataType";
import moment from "moment";
import { LocationSelectContext } from "../../../context/LocationSelectPageContext";
import HistoryViewDetailsDialog from "./HistoryViewDetailsDialog";
import { RegionContext } from "../../../context/RegionContext";

interface DownloadFileResposeType {
  file_url: string;
}

const ClimateDataHistoryModal = (props: ClimateDataHistoryModalPropsType) => {
  //eslint-disable-next-line
  const { mapViewPort, historyTab, outMMarker } = useContext(RegionContext);
  const [viewport, setViewport] = mapViewPort;
  //eslint-disable-next-line
  const [outputMarker, setOutputMarker] = outMMarker;
  const { open, closeModalHandler } = props;

  const classes = useClimateDataHistoryModalStyles();

  const {
    handlers,
    historyDialogState,
    climateDataHistoryModalState,
  } = useContext(LocationSelectContext);

  const { setIsClimateDataHistoryModalOpen } = climateDataHistoryModalState;

  const {
    isHistoryViewDetailsDialogOpen,
    setIsHistoryViewDetailsDialogOpen,
  } = historyDialogState;

  const { closeAllModalsAndPopups } = handlers;
  //eslint-disable-next-line
  const [displayData, setDisplayData] = useState<DisplayData>();

  const [historyData, setHistoryData] = useState<History[]>([]);

  const [selectedHistoryCardId, setSelectedHistoryCardId] = useState<number>();

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const { data } = await ServicesApi.get<History[]>("/history");

        setHistoryData(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchHistory();
  }, [open]);

  const downloadCsvHandler = async () => {
    try {
      const { data } = await ServicesApi.get<DownloadFileResposeType>(
        "/files/csv-gis",

        {
          params: {
            id: selectedHistoryCardId,
            file_format: "csv",
          },
        }
      );

      // for downloading file automatically
      const link = document.createElement("a");
      link.href = `${data.file_url}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  const breakpoints = {
    default: 4,
    1100: 3,
    700: 2,
  };

  return (
    <>
      <CustomModal
        width="100%"
        isOpen={open}
        title="Your climate data search history"
        headingMaxWidth="22.5rem"
        onClick={closeModalHandler}
      >
        <Masonry
          breakpointCols={breakpoints}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {historyData.map((item, index) => (
            <ClimateDataHistoryCard
              onClick={() => {
                closeAllModalsAndPopups();
                setIsHistoryViewDetailsDialogOpen(true);
                setDisplayData(item.display_data);
                setSelectedHistoryCardId(item.id);
                setOutputMarker({ visible: true, selected: 0 });

                setViewport({
                  ...viewport,
                  zoom: 3,
                  latitude: historyData[index].lat[0],
                  longitude: historyData[index].lon[0],
                });
              }}
              projectName={item.project_name}
              dataSource={item.datasource}
              variable={item?.variable_long_name!}
              location={item?.region_long_name!}
              requestedDate={moment(item.current_item_status_date).format(
                "DD-MM-YYYY"
              )}
              lat={item.lat}
              long={item.lon}
              key={item.id}
              isRetrievingData={item.status !== "success"}
              downloadCsv={() => {
                setSelectedHistoryCardId(item.id);
                downloadCsvHandler();
              }}
              status={item.status}
              style={{
                marginLeft:
                  index === 0
                    ? undefined
                    : historyData.length === 2
                    ? "2.5rem"
                    : undefined,
              }}
            />
          ))}
          <Button
            onClick={() => window.location.reload()}
            className={classes.cardAddMoreButton}
            style={{
              marginLeft:
                historyData.length <= 2
                  ? historyData.length === 1
                    ? "7.5rem"
                    : "5rem"
                  : undefined,
            }}
          >
            <PlusIcon />
          </Button>
        </Masonry>
      </CustomModal>

      <HistoryViewDetailsDialog
        open={isHistoryViewDetailsDialogOpen}
        dialogCloseHandler={() => setIsHistoryViewDetailsDialogOpen(false)}
        downloadCsvHandler={downloadCsvHandler}
        backToHistoryHandler={() => setIsClimateDataHistoryModalOpen(true)}
        selectedHistoryCardId={selectedHistoryCardId}
      />
    </>
  );
};

export default ClimateDataHistoryModal;

import React, { Fragment } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

import noaaLocaLogo from "../../../../../images/noaa.svg";
import cmip6Logo from "../../../../../images/cmip6.svg";
import cordexLogo from "../../../../../images/cordex.png";
import ukpc18Logo from "../../../../../images/ukpc18.png";
import { ModelApiResponseType } from "../../../../../types/types";

const useStyles = makeStyles({
  heading: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "15.15px",
  },

  body: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "15.15px",
  },

  sideBySideText: {
    display: "flex",

    "& p": {
      marginLeft: "5px",
    },
  },

  tableContainer: {
    "& table": {
      borderCollapse: "collapse",
      width: "80%",
    },

    "& td, th ": {
      border: "1px solid #dddddd",
      textAlign: "left",
      padding: "8px",
    },
  },

  logo: { height: "9.375rem" },
});

type PropsType = {
  data: ModelApiResponseType;
};

export const CordexContent = (props: PropsType) => {
  const classes = useStyles();

  const { data } = props;

  return (
    <>
      <img
        src={cordexLogo}
        className={classes.logo}
        style={{ width: "11.4375rem" }}
        alt="cordex-dataset-logo"
      />
      <br />
      <div>
        <Typography variant="h6" className={classes.heading}>
          CORDEX
        </Typography>
        <Typography variant="body1" className={classes.body}>
          https://cordex.org/
        </Typography>
      </div>

      <br />
      <div className={classes.sideBySideText}>
        <Typography variant="h6" className={classes.heading}>
          Last Updated:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          {data.last_updated}
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          BACKGROUND
        </Typography>

        <br />
        <Typography variant="body1" className={classes.body}>
          The COordinated Regional climate Downscaling EXperiment (CORDEX) is a
          collaborative excersie to advance and coordinate the science and
          application of regional climate downscaling through global
          partnerships. There are a total of fourteen official CORDEX domains
          with a federated organization building on a common experimental
          framework.
        </Typography>
      </div>

      <br />

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Emission Scenarios:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          {data.covered_emission_scenarios?.map((item, i) => (
            <Fragment key={`${item}-${i}`}>
              {item} <br />
            </Fragment>
          ))}
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Spatial Resolution:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          {data.spatial_resolution?.map((item, i) => (
            <Fragment key={`${item}-${i}`}>
              {item} <br />
            </Fragment>
          ))}
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Temporal Resolution:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          {data.temporal_resolution}
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Time Period Coverage:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          {data.time_period_coverage?.map((item, i) => (
            <Fragment key={`${item}-${i}`}>
              {item} <br />
            </Fragment>
          ))}
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          MODELS
        </Typography>{" "}
        <br />
        <Typography
          variant="body1"
          className={classes.body}
          style={{ color: "red" }}
        >
          Model Details <br />
          {data.model_table?.model_details}
        </Typography>
        <br />
        <div className={classes.tableContainer}>
          <table>
            <thead>
              <tr>
                {data.model_table?.headers?.map((e, i) => (
                  <th key={`${e}-${i}`}>
                    <Typography variant="h6" className={classes.heading}>
                      {e}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.model_table?.rows?.map((item, i) => (
                <tr key={`${item}-${i}`}>
                  <td>
                    <Typography variant="body1" className={classes.body}>
                      {item.institution}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body1" className={classes.body}>
                      {item.gcmDrivingModel}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="body1" className={classes.body}>
                      {item.rcmModel}
                    </Typography>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <br />
      <div>
        <Typography variant="h6" className={classes.heading}>
          Arup Notes
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Benefits: <br />
          - Global coverage <br />
          - Regional models at finer resolution <br />- GCMs - more suitable for
          capturing local effects and extreme events
        </Typography>
        <br />
        <Typography variant="body1" className={classes.body}>
          Limitations: <br />- Inconsistent models when comparing datasets from
          different regions <br />
          - Limited number of models in some regions <br />- RCP 4.5 only
          available for courser resolution <br />- For some regions, RCP 4.5 is
          only available for more course resolutions
        </Typography>
      </div>
      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          FURTHER CORDEX PRODUCTS
        </Typography>
        <Typography variant="body1" className={classes.body}>
          A selection of CORDEX models are available at hourly resolution. If
          you would like more information please contact KliimaSupport@arup.com
        </Typography>
      </div>

      <br />
      <div>
        <Typography variant="h6" className={classes.heading}>
          REFERENCE &amp; CITATION
        </Typography>
        <br />
        <Typography variant="body1" className={classes.body}>
          We acknowledge the World Climate Research Programme&apos;s Working
          Group on Regional Climate, and the Working Group on Coupled Modelling,
          former coordinating body of CORDEX and responsible panel for CMIP5. We
          also thank the climate modelling groups for producing and making
          available their model output. We also acknowledge the Earth System
          Grid Federation infrastructure, an international effort led by the
          U.S. Department of Energy&apos;s Program for Climate Model Diagnosis
          and Intercomparison, the European Network for Earth System Modelling
          and other partners in the Global Organisation for Earth System Science
          Portals (GO-ESSP).
        </Typography>
      </div>
    </>
  );
};

export const Ukpc18Content = () => {
  const classes = useStyles();

  return (
    <>
      <img
        src={ukpc18Logo}
        className={classes.logo}
        style={{ width: "11.4375rem" }}
        alt="ukpc18-dataset-logo"
      />

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          UKCP18 Regional and Local Models
        </Typography>
        <Typography variant="body1" className={classes.body}>
          https://www.metoffice.gov.uk/research/approach/collaboration/ukcp/index
        </Typography>
      </div>

      <br />
      <div className={classes.sideBySideText}>
        <Typography variant="h6" className={classes.heading}>
          Last Updated:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Last Updated:
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          BACKGROUND
        </Typography>

        <br />
        <Typography variant="body1" className={classes.body}>
          The UK Climate Projections (UKCP) are the most up-to-date assessment
          of how the UK climate may change in the future. The projections form
          part of the Met Office Hadley Centre Climate Programme. The Department
          of Business, Energy and Industrial Strategy (BEIS) and the Department
          for Environment, Food and Rural Affairs (Defra) support the UKCP.
        </Typography>
      </div>

      <br />

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Emission Scenarios:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          RCP 8.5
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Spatial Resolution:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          5km (Local 2.2km projections regridded to OSGB grid) 12km (Regional)
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Temporal Resolution:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Daily
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Time Period Coverage:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          1981-2079
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          MODELS
        </Typography>{" "}
        <br />
        <Typography variant="body1" className={classes.body}>
          12 Regional Models used for daily timeseries calculations.
        </Typography>
        <br />
      </div>

      <br />
      <div>
        <Typography variant="h6" className={classes.heading}>
          Arup Notes
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Benefits: <br />
          - Latest projections for the UK from the MetOffice <br />- Projections
          available at high spatial resolution (5 km and 12 km)
        </Typography>
        <br />
        <Typography variant="body1" className={classes.body}>
          Limitations: <br />- RCP 8.5 only <br /> - Coverage limited to the UK
        </Typography>
      </div>
      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          FURTHER UKCP18 PRODUCTS
        </Typography>
        <br />
        <Typography variant="body1" className={classes.body}>
          https://ukclimateprojections-ui.metoffice.gov.uk/ui/home
          <br />
          Probabilistic projections (3000 samples) available on UKCP18 User
          Interface
          <br />
          Marine Projections including Sea Level Rise available on UKCP18 User
          Interface
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          USEFUL SOURCES
        </Typography>
        <Typography variant="h6" className={classes.heading}>
          USEFUL SOURCES
        </Typography>
        <br />
        <Typography variant="h6" className={classes.heading}>
          UKCP18 Science Overview Report
        </Typography>

        <Typography variant="body1" className={classes.body}>
          https://www.metoffice.gov.uk/pub/data/weather/uk/ukcp18/science-reports/UKCP18-Overview-report.pdf
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          REFERENCE &amp; CITATION
        </Typography>
        <br />
        <Typography variant="body1" className={classes.body}>
          All data is provided under the Open Government Licence. <br />
          Fung F, Lowe J, Mitchell JFB, Murphy J, Bernie D, Gohar L, Harris G,
          Howard T, Kendon E, Maisey P, Palmer M and Sexton D (2018). UKCP18
          Guidance: Caveats and Limitations. Met Office Hadley Centre, Exeter.
          <br />
          Met Office Hadley Centre (2018): UKCP18 Regional Projections on a 12km
          grid over the UK for 1980-2080. Centre for Environmental Data
          Analysis, date of citation.
          https://catalogue.ceda.ac.uk/uuid/589211abeb844070a95d061c8cc7f604
          <br />
          Met Office Hadley Centre (2019): UKCP Local Projections on a 5km grid
          over the UK for 1980-2080. Centre for Environmental Data Analysis,
          date of citation.
          <br />
          https://catalogue.ceda.ac.uk/uuid/e304987739e04cdc960598fa5e4439d0
          <br />
          Use of these data is covered by the following licence:
          <br />
          http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/.
        </Typography>
      </div>
    </>
  );
};

export const NoaaLocaContent = () => {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <img
          src={noaaLocaLogo}
          className={classes.logo}
          alt="noaa-loca-dataset-logo"
        />

        <Box ml="2rem">
          <Typography variant="h6" className={classes.heading}>
            LOCA
          </Typography>
          <br />
          <Typography variant="body1" className={classes.body}>
            The LOCA (Localized Constructed Analogs) dataset includes downscaled
            projections from 32 global climate models calculated for two
            Representative Concentration Pathways (RCP 4.5 and RCP 8.5). Each of
            the climate projections includes daily maximum temperature, minimum
            temperature, and precipitation for every 6x6km for the conterminous
            US from 1950 to 2100. LOCA attempts to better preserve extreme hot
            days and heavy rain events, regional patterns of precipitation.
            <br />
            All data is provided under US government works licence.
          </Typography>
        </Box>
      </Box>

      <br />
      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          LOCA
        </Typography>
        <Typography variant="body1" className={classes.body}>
          http://loca.ucsd.edu/
        </Typography>
      </div>

      <br />
      <div className={classes.sideBySideText}>
        <Typography variant="h6" className={classes.heading}>
          Last Updated:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          October 2020
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          BACKGROUND
        </Typography>

        <br />
        <Typography variant="body1" className={classes.body}>
          The LOCA (Localized Constructed Analogs) dataset includes downscaled
          projections from 32 global climate models calculated for two
          Representative Concentration Pathways (RCP 4.5 and RCP 8.5). Each of
          the climate projections includes daily maximum temperature, minimum
          temperature, and precipitation for every 6x6km for the conterminous US
          from 1950 to 2100. LOCA attempts to better preserve extreme hot days
          and heavy rain events, regional patterns of precipitation.
        </Typography>
      </div>

      <br />

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Emission Scenarios:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          RCP 4.5 <br />
          RCP 8.5
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Spatial Resolution:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          1/16th degree (6km)
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Temporal Resolution:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Daily
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Time Period Coverage:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Historical (1950-2005) <br />
          Future (2006-2100)
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          MODELS
        </Typography>{" "}
        <br />
        <Typography variant="body1" className={classes.body}>
          32 global climate models from the CMIP5 archive downscaled (add list)
        </Typography>
        <br />
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Arup Notes
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Benefits: <br /> -High spatial resolution <br /> -Large number of
          models used in the downscaling
        </Typography>
        <br />
        <Typography variant="body1" className={classes.body}>
          Limitations: <br /> -Limited to US
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          USEFUL SOURCES
        </Typography>

        <Typography variant="body1" className={classes.body}>
          https://scenarios.globalchange.gov/loca-viewer/{" "}
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          REFERENCE &amp; CITATION
        </Typography>
        <br />
        <Typography variant="body1" className={classes.body}>
          All data is provided under US government works licence.
        </Typography>
      </div>
    </>
  );
};

export const Cmip6Content = () => {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <img
          src={cmip6Logo}
          style={{ width: "9.375rem", height: "2rem" }}
          alt="cmip6-dataset-logo"
        />

        <Box ml="2rem">
          <Typography variant="body1" className={classes.body}>
            We acknowledge the World Climate Research Programme&apos;s Working
            Group on Regional Climate, and the Working Group on Coupled
            Modelling, former coordinating body of CORDEX and responsible panel
            for CMIP5. We also thank the climate modelling groups for producing
            and making available their model output. We also acknowledge the
            Earth System Grid Federation infrastructure an international effort
            led by the U.S. Department of Energy&apos;s Program for Climate
            Model Diagnosis and Intercomparison, the European Network for Earth
            System Modelling and other partners in the Global Organisation for
            Earth System Science Portals (GO-ESSP).
            <br />
            <br />
            The Coordinated Regional Downscaling Experiment (CORDEX) is a CMIP6
            diagnostic MIP requesting specific CMIP6 output for regional climate
            downscaling. CORDEX builds on a foundation of previous downscaling
            intercomparison projects to provide a common framework for
            downscaling activities around the world. The CORDEX Regional
            Challenges provide a focus for downscaling research and a basis for
            making use of CMIP6 global output to produce downscaled projected
            changes in regional climates, and assess sources of uncertainties in
            the projections.
          </Typography>
        </Box>
      </Box>

      <br />
      <br />

      <br />
      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          CMPI6
        </Typography>
        <Typography variant="body1" className={classes.body}>
          wcrp-climate.org/wgcm-cmip/wgcm-cmip5 <br />
          https://pcmdi.llnl.gov/mips/cmip5/
        </Typography>
      </div>

      <br />
      <div className={classes.sideBySideText}>
        <Typography variant="h6" className={classes.heading}>
          Last Updated:
        </Typography>
        <Typography variant="body1" className={classes.body}></Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          BACKGROUND
        </Typography>

        <br />
        <Typography variant="body1" className={classes.body}>
          Under the World Climate Research Programme (WCRP) the Working Group on
          Coupled Modelling (WGCM) established the Coupled Model Intercomparison
          Project (CMIP) as a standard global experimental protocol for studying
          the output of coupled atmosphere-ocean general circulation models
          (GCM). The CMIP5 project is the most current and extensive used in the
          AR5 report by the IPCC (published 2013)
        </Typography>
      </div>

      <br />

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Emission Scenarios:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          RCP 4.5 <br />
          RCP 8.5
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Spatial Resolution:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          150km
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Temporal Resolution:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Daily
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          Time Period Coverage:
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Historical (1950-2005) <br />
          Future (2006-2100)
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          MODELS
        </Typography>{" "}
        <br />
        <Typography variant="body1" className={classes.body}>
          35 GCMs
        </Typography>
        <br />
      </div>

      <br />
      <div>
        <Typography variant="h6" className={classes.heading}>
          Arup Notes
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Benefits: <br />
          -Globally and extensively used by climate community <br />
          -Used to inform IPCC AR5 Report
        </Typography>
        <br />
        <Typography variant="body1" className={classes.body}>
          Limitations: <br /> - Course resolution
        </Typography>
      </div>

      <br />
      <div>
        <Typography variant="h6" className={classes.heading}>
          FURTHER CMIP PRODUCTS
        </Typography>
        <Typography variant="body1" className={classes.body}>
          CMIP6 has been released and used to inform the 2021 IPCC AR6 Report.
          The Kliima team are currently developing capabilities to add this
          dataset. Please see the Sharepoint for more details.
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          USEFUL SOURCES
        </Typography>
        <br />
        <Typography variant="h6" className={classes.heading}>
          IPCC AR5 Report
        </Typography>
        <Typography variant="body1" className={classes.body}>
          https://www.ipcc.ch/report/ar5/syr/
        </Typography>
      </div>

      <br />

      <div>
        <Typography variant="h6" className={classes.heading}>
          REFERENCE &amp; CITATION
        </Typography>
        <br />
        <Typography variant="body1" className={classes.body}>
          https://pcmdi.llnl.gov/mips/cmip5/citation.html{" "}
        </Typography>

        <Typography variant="body1" className={classes.body}>
          We acknowledge the World Climate Research Programme&apos;s Working
          Group on Regional Climate, and the Working Group on Coupled Modelling,
          former coordinating body of CORDEX and responsible panel for CMIP5. We
          also thank the climate modelling groups for producing and making
          available their model output. We also acknowledge the Earth System
          Grid Federation infrastructure, an international effort led by the
          U.S. Department of Energy&apos;s Program for Climate Model Diagnosis
          and Intercomparison, the European Network for Earth System Modelling
          and other partners in the Global Organisation for Earth System Science
          Portals (GO-ESSP).
        </Typography>
      </div>
    </>
  );
};

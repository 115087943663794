export type SourceDataType = {
  title: string;
  unit: string;
  description: string;
};

export const temperatureRelatedSources: SourceDataType[] = [
  {
    title: "Maximum temperature",
    unit: "Txx Units output: °C",
    description:
      "The change in annual maximum value of  daily maximum temperature.",
  },
  {
    title: "Average daily temperature",
    unit: "Tas Units output: °C",
    description: "The change in average daily mean temperature.",
  },
  {
    title: "Average daily maximum temperature",
    unit: "Tasmax Units output: °C",
    description: "The change in daily maximum temperature.",
  },
  {
    title: "Average daily minimum temperature",
    unit: "Tasmin Units output: °C",
    description: "The change in daily minimum temperature.",
  },
  {
    title: "Temperature threshold more than",
    unit: "Units output: °C",
    description:
      "Annual count when maximum temperature is above threshold value",
  },
  {
    title: "Temperature threshold less than",
    unit: "Units output: °C",
    description:
      "Annual count when minimum temperature is below threshold value",
  },
];

export const windRelatedSources: SourceDataType[] = [
  {
    title: "Daily max gust",
    unit: "wsgsmax Units output: m/s",
    description: "The change in daily maximum wind gust",
  },
  {
    title: "Gust threshold more than.",
    unit: "Units output: m/s",
    description:
      "Annual count when daily maximum wind gust is above threshold value",
  },
  {
    title: "Daily average wind speed",
    unit: "sfcWind Units output: m/s",
    description: "The change in daily average surface wind speed",
  },
  {
    title: "Wind Threshold more than",
    unit: "Units output: m/s",
    description:
      "Annual count when daily average surface wind is above threshold value",
  },
];

export const humdityRelatedSources: SourceDataType[] = [
  {
    title: "Specfic humidity",
    unit: "Huss Units output: %",
    description: "The change in annual specifc humidity",
  },
  {
    title: "Relative humidity",
    unit: "Huss Units output: %",
    description: "The change in annual relative humidity",
  },
];

export const snowRelatedSources: SourceDataType[] = [
  {
    title: "Snow depth",
    unit: "Snd Units output: m",
    description: "The change in thickness of lying snow",
  },
  {
    title: "Snow threshold more than",
    unit: "Units output: m",
    description: "Annual count when snow depth is above threshold value",
  },
];

export const marineRelatedDataSources: SourceDataType[] = [
  {
    title: "Sea level Rise",
    unit: "m Unit output: m",
    description: "Time mean sea level rise ",
  },
];

export const precipitationRelatedDataSources: SourceDataType[] = [
  {
    title: "Maximum 1 day rainfall",
    unit: "Rx1day Units output: mm",
    description:
      "The change in annual maximum value of average daily precipitation",
  },
  {
    title: "Maximum 5 day rainfall",
    unit: "Rx5day Units output: mm",
    description:
      "The change in annual maximum value of average daily precipitation over 5 consecutive days",
  },
  {
    title: "Cumulative dry days",
    unit: "Cdd Units output: mm",
    description:
      "Maximum length of dry spell (consecutive days were daily precipitation is less than Xmm)",
  },
  {
    title: "Average daily precipitation",
    unit: "Pr Units output: mm",
    description: "The change in average daily precipitation ",
  },
  {
    title: "Precipition Threshold more than",
    unit: "Units output: mm",
    description:
      "Annual count when average daily precipitation is above threshold value",
  },
  {
    title: "Precipition threshold less than",
    unit: "Units output: mm",
    description:
      "Annual count when average daily precipitation is below threshold value",
  },
];

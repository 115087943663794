import React, { useContext } from "react";
import { Container } from "@material-ui/core";

import SelectLocation from "./SelectLocation";
import SelectClimateVariable from "./SelectClimateVariable";
import ConfigureDataset from "./ConfigureDataset";
import VariableSelectionModal from "./VariableSelectionModal/VariableSelectionModal";
import WelcomeToTheArupModal from "./WelcomeToTheArupModal/WelcomeToTheArupModal";
import WindRelatedVariablesModal from "./WindRelatedVariablesModal/WindRelatedVariablesModal";
import DatasetSelectionModal from "./DatasetSelectionModal/DatasetSelectionModal";
import ClimateDataHistoryModal from "./ClimateDataHistoryModal/ClimateDataHistoryModal";
import ProjectDescriptionModal from "./ProjectDescriptionModal/ProjectDescriptionModal";
import Mapbox from "../MapBox/MapBox";
import OtherToolCard from "./OtherToolCard";
import { LocationSelectContext } from "../../context/LocationSelectPageContext";

import { useLocationSelectPageBackupStyles } from "./useStyles";
import ClimateDataSetModal from "./ClimateDataSetModal/ClimateDataSetModal";
import TermsOfUseModal from "./TermsOfUseModal/TermsOfUseModal";
import { RegionContext } from "../../context/RegionContext";

const LocationSelectPage_Backup = () => {
  const classes = useLocationSelectPageBackupStyles();

  const {
    welcomeToTheArupModalState,
    variableSelectionModalState,
    selectClimateVariableCardVisibleState,
    windRelatedVariablesModalState,
    configureDatasetVisibleState,
    datasetSelectionModalState,
    climateDataHistoryModalState,
    projectDescriptionModalState,
    climateDatasetModalState,
    otherToolCardVisibileState,
    selectLocationCardVisibleState,
    termsOfUserModalState,
  } = useContext(LocationSelectContext);

  const { isSelectLocationCardVisible } = selectLocationCardVisibleState;

  const {
    isVariableSelectionModalOpen,
    setIsVariableSelectionModalOpen,
  } = variableSelectionModalState;

  const {
    isWelcomeToTheArupModalOpen,
    setIsWelcomeToTheArupModalOpen,
  } = welcomeToTheArupModalState;

  const {
    isSelectClimateVariableCardVisible,
  } = selectClimateVariableCardVisibleState;

  const {
    isWindRelatedVariablesModalOpen,
    setIsWindRelatedVariablesModalOpen,
  } = windRelatedVariablesModalState;

  const {
    isProjectDescriptionModalOpen,
    setIsProjectDescriptionModalOpen,
  } = projectDescriptionModalState;

  const {
    isClimateDataHistoryModalOpen,
    setIsClimateDataHistoryModalOpen,
  } = climateDataHistoryModalState;

  const {
    setIsClimateDatasetModalOpen,
    isClimateDatasetModalOpen,
  } = climateDatasetModalState;

  const { isConfigureDatasetVisible } = configureDatasetVisibleState;

  const {
    isDatasetSelectionModalOpen,
    setIsDatasetSelectionModalOpen,
  } = datasetSelectionModalState;

  const {
    setIsTermsOfUseModalOpen,
    isTermsOfUseModalOpen,
  } = termsOfUserModalState;

  const {
    isOtherToolCardVisibile,
    setIsOtherToolCardVisibile,
  } = otherToolCardVisibileState;

  const { geoJSON } = useContext(RegionContext);

  const [geoJson] = geoJSON;

  const searchboxRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (isConfigureDatasetVisible) {
      const len = searchboxRef.current?.scrollHeight as number;
      searchboxRef.current?.scrollTo(0, len);
    }
  }, [isConfigureDatasetVisible]);

  const closeModalHandler = (modal: string) => {
    switch (modal) {
      case "welcomeModal":
        setIsWelcomeToTheArupModalOpen(false);
        setIsOtherToolCardVisibile(false);
        break;

      case "variableSelectionModal":
        setIsVariableSelectionModalOpen(false);
        break;

      case "windRelatedVariableModal":
        setIsWindRelatedVariablesModalOpen(false);
        break;

      case "datasetSelectionModal":
        setIsDatasetSelectionModalOpen(false);
        break;

      case "climateHistoryDataModal":
        setIsClimateDataHistoryModalOpen(false);
        break;

      case "projectDescriptionModal":
        setIsProjectDescriptionModalOpen(false);
        break;

      case "climateDatasetModal":
        setIsClimateDatasetModalOpen(false);
        break;

      case "termsOfUseModal":
        setIsTermsOfUseModalOpen(false);
        break;
    }
  };

  const shouldMapRender = () => {
    //eslint-disable-next-line
    const isThereAModal =
      !isWelcomeToTheArupModalOpen &&
      !isVariableSelectionModalOpen &&
      !isWindRelatedVariablesModalOpen &&
      !isDatasetSelectionModalOpen &&
      !isClimateDataHistoryModalOpen &&
      !isProjectDescriptionModalOpen &&
      !isClimateDatasetModalOpen;

    // if (isThereAModal) {
    return <Mapbox />;
    // }
  };

  return (
    <section className={classes.mainContainer}>
      <div className={classes.mapboxContainer}>{shouldMapRender()}</div>
      <Container>
        <div className={classes.searchBoxContainer}>
          <div
            ref={searchboxRef}
            style={{
              zIndex: 3,
              display: isWelcomeToTheArupModalOpen
                ? "flex"
                : !isSelectLocationCardVisible
                ? "none"
                : "",
              flexDirection: isWelcomeToTheArupModalOpen ? "column" : "row",

              marginRight: "1rem",
              height:
                isWelcomeToTheArupModalOpen ||
                isConfigureDatasetVisible ||
                geoJson.features.length > 4
                  ? "calc(100vh - 64px)"
                  : "fit-content",
              overflowY: "scroll",
              overflowX: "hidden",
              minWidth: "368px",
              paddingRight: "16px",
            }}
          >
            <SelectLocation isVisible={isSelectLocationCardVisible} />

            <SelectClimateVariable
              isVisible={isSelectClimateVariableCardVisible}
            />

            <ConfigureDataset isVisible={isConfigureDatasetVisible} />

            <OtherToolCard isOtherToolCardVisibile={isOtherToolCardVisibile} />
          </div>

          <div className={classes.modalsContainer}>
            <WelcomeToTheArupModal
              open={isWelcomeToTheArupModalOpen}
              closeModalHandler={() => closeModalHandler("welcomeModal")}
            />

            <VariableSelectionModal
              open={isVariableSelectionModalOpen}
              closeModalHandler={() =>
                closeModalHandler("variableSelectionModal")
              }
            />

            <WindRelatedVariablesModal
              open={isWindRelatedVariablesModalOpen}
              closeModalHandler={() =>
                closeModalHandler("windRelatedVariableModal")
              }
            />

            <DatasetSelectionModal
              open={isDatasetSelectionModalOpen}
              closeModalHandler={() =>
                closeModalHandler("datasetSelectionModal")
              }
            />

            <ClimateDataSetModal
              open={isClimateDatasetModalOpen}
              closeModalHandler={() => closeModalHandler("climateDatasetModal")}
            />

            <ProjectDescriptionModal
              open={isProjectDescriptionModalOpen && !isClimateDatasetModalOpen}
              closeModalHandler={() =>
                closeModalHandler("projectDescriptionModal")
              }
            />

            <ClimateDataHistoryModal
              open={isClimateDataHistoryModalOpen}
              closeModalHandler={() =>
                closeModalHandler("climateHistoryDataModal")
              }
            />

            <TermsOfUseModal
              open={isTermsOfUseModalOpen}
              closeModalHandler={() => closeModalHandler("termsOfUseModal")}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default LocationSelectPage_Backup;

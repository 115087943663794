import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "@material-ui/core";

import SearchBox from "../../partials/SearchBox";
import CustomDropdown from "../../partials/CustomDropdown";
import { CSSTransition } from "react-transition-group";
import { LocationSelectContext } from "../../context/LocationSelectPageContext";
import { RegionContext } from "../../context/RegionContext";

import {
  ConfigureDatasetPropTypes,
  useConfigureDatasetStyles,
} from "./useStyles";
import { availableDatasets } from "./DatasetSelectionModal/DatasetSelectionModal";
import LatLonTextField from "../../partials/LatLonTextField";

const ConfigureDataset = ({ isVisible }: ConfigureDatasetPropTypes) => {
  const classes = useConfigureDatasetStyles();

  const { mapdata, geoJSON } = useContext(RegionContext);

  const [map] = mapdata;

  const [geoJson] = geoJSON;

  const {
    selectedDatasetState,
    projectDescriptionModalState,
    datasetSelectionModalState,
    selectedClimateVariableSourceState,
    selectedClimateVariableState,

    retrivableData,
    climateDatasetModalState,
    lessThanMoreThanValuesState,
    focusOnHistoryProjectionDpdState,
    doesEachFieldOfConfigDatasetCardHasValueState,
  } = useContext(LocationSelectContext);
  //eslint-disable-next-line
  const [retriveData, setRetriveData] = retrivableData;

  const { lessThanMoreThanValues } = lessThanMoreThanValuesState;

  const { selectedDataset, setSelectedDataset } = selectedDatasetState;

  const { selectedClimateVariableSource } = selectedClimateVariableSourceState;

  const { selectedClimateVariable } = selectedClimateVariableState;

  const { setIsClimateDatasetModalOpen } = climateDatasetModalState;

  const {
    setIsProjectDescriptionModalOpen,
    isProjectDescriptionModalOpen,
  } = projectDescriptionModalState;

  const { setIsDatasetSelectionModalOpen } = datasetSelectionModalState;

  const {
    shouldFocusOnHistoryProjectionDpd,
    setShouldFocusOnHistoryProjectionDpd,
  } = focusOnHistoryProjectionDpdState;

  const {
    setDoesEachFieldOfConfigDatasetCardHasValue,
    doesEachFieldOfConfigDatasetCardHasValue,
  } = doesEachFieldOfConfigDatasetCardHasValueState;

  const [state, setState] = useState({
    dataset: "",
    from: "",
    projection: "",
  });

  const [historicalDataDpdOptions, setHistoricalDataDpdOptions] = useState<
    string[]
  >([]);

  const [projectedDataDpdOptions, setProjectedDataDpdOptions] = useState<
    string[]
  >([]);

  const [historicalYear, setHistoricalYear] = useState({
    from: "",
    to: "",
  });

  const [projectedYear, setProjectedYear] = useState({
    from: "",
    to: "",
  });

  const historicalDpdRef = useRef<HTMLInputElement | null>(null);
  const projectionDpdRef = useRef<HTMLInputElement | null>(null);

  const [datasets, setDatasets] = useState<string[]>([]);

  useEffect(() => {
    const sets: string[] = [];

    availableDatasets.forEach((item) => {
      if (item.isAvailable) {
        sets.push(item.name);
      }
    });

    setDatasets(sets);
  }, []);

  const onChangeHandler = (event) => {
    const selectedValue = event.target.value.toUpperCase();

    setState({
      ...state,
      [event.target.name]: selectedValue,
    });

    //if user select the dataset from the dropdown
    // the selected option will be selected into the modal
    event.target.name === "dataset" && setSelectedDataset(selectedValue);
  };

  const setDpdOptions: () => void = () => {
    switch (state.dataset) {
      case "CORDEX":
        setHistoricalDataDpdOptions(["1986-2005", "1976-2005"]);

        if (state.from === "1986-2005") {
          setProjectedDataDpdOptions([
            "2010-2029",
            "2020-2039",
            "2030-2049",
            "2050-2069",
            "2060-2079",
            "2070-2089",
            "2080-2099",
           
          ]);
        } else if (state.from === "1976-2005") {
          setProjectedDataDpdOptions([
            // "2010-2039",
            // "2020-2049",
            // "2050-2069",
            // "2070-2099",
            "2010-2039",
            "2020-2049",
            "2030-2059",
            "2040-2069",
            "2050-2079",
            "2060-2089",
            "2070-2099",
          ]);
        }
        break;

      case "NOAA LOCA":
        setHistoricalDataDpdOptions([]);
        setProjectedDataDpdOptions([]);
        break;

      case "UKCP-18":
        setHistoricalDataDpdOptions([]);
        setProjectedDataDpdOptions([]);
        break;

      case "CMIP6":
        setHistoricalDataDpdOptions([]);
        setProjectedDataDpdOptions([]);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (selectedDataset) {
      setState({
        ...state,
        dataset: selectedDataset,
      });
    }

    setDpdOptions();

    if (state.dataset) {
      setIsClimateDatasetModalOpen(false);
      setIsDatasetSelectionModalOpen(true);
    }
    //eslint-disable-next-line
  }, [selectedDataset, state.dataset, state.from]);

  useEffect(() => {
    if (shouldFocusOnHistoryProjectionDpd && !state.from) {
      historicalDpdRef.current?.focus();
    }

    if (shouldFocusOnHistoryProjectionDpd && !state.projection) {
      projectionDpdRef.current?.focus();
    }

    const timeoutId = setTimeout(() => {
      setShouldFocusOnHistoryProjectionDpd(false);
    }, 500);

    //eslint-disable-next-line
    return () => clearTimeout(timeoutId);
    //eslint-disable-next-line
  }, [shouldFocusOnHistoryProjectionDpd]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "preproduction") {
      if (state.dataset && state.from && state.projection) {
        setDoesEachFieldOfConfigDatasetCardHasValue(true);
        return;
      }
    } else {
      if (
        state.dataset &&
        historicalYear.from &&
        historicalYear.to &&
        projectedYear.to &&
        projectedYear.from
      ) {
        setDoesEachFieldOfConfigDatasetCardHasValue(true);
        return;
      }
    }
    setDoesEachFieldOfConfigDatasetCardHasValue(false);
  }, [
    state,
    setDoesEachFieldOfConfigDatasetCardHasValue,
    historicalYear,
    projectedYear,
  ]);

  const data = {
    region: map.region,
    country: map.country,
    lat: map.Longitude,
    lon: map.Latitude,
    climateVariable: selectedClimateVariable,
    climateVariableSource: selectedClimateVariableSource,
    thresholdValueLessThan: lessThanMoreThanValues.thresholdValueLessThan,
    thresholdValueMoreThan: lessThanMoreThanValues.thresholdValueMoreThan,
    dataset: selectedDataset,
    historical_from_year:
      process.env.REACT_APP_ENV === "preproduction"
        ? state.from.split("-")[0]
        : historicalYear.from,
    historical_to_year:
      process.env.REACT_APP_ENV === "preproduction"
        ? state.from.split("-")[1]
        : historicalYear.to,
    projectional_from_year:
      process.env.REACT_APP_ENV === "preproduction"
        ? state.projection.split("-")[0]
        : projectedYear.from,
    projectional_to_year:
      process.env.REACT_APP_ENV === "preproduction"
        ? state.projection.split("-")[1]
        : projectedYear.to,

    projection: state.from,
    geoJson: geoJson.features,
    projectedData: state.projection,
  };

  const onRetriveData = () => {
    setRetriveData(data);
    setIsDatasetSelectionModalOpen(false);
    setIsProjectDescriptionModalOpen(true);
  };

  //eslint-disable-next-line
  useEffect(() => setRetriveData(data), [isProjectDescriptionModalOpen]);

  const clearAllHandler = () => {
    setState({
      dataset: "",
      from: "",
      projection: "",
    });
    setSelectedDataset("");
    setHistoricalYear({ from: "", to: "" });
    setProjectedYear({ from: "", to: "" });
  };

  return (
    <CSSTransition in={isVisible} timeout={300} classNames="item" unmountOnExit>
      <div className={classes.root}>
        <SearchBox
          overlay={isProjectDescriptionModalOpen}
          title="Select &amp; Configure Dataset"
          editClickHandler={() => {
            setIsProjectDescriptionModalOpen(false);
            setIsDatasetSelectionModalOpen(true);
          }}
          infoClickHandler={() => {
            setIsClimateDatasetModalOpen(true);
            setIsDatasetSelectionModalOpen(false);
            setIsProjectDescriptionModalOpen(false);
          }}
        >
          <CustomDropdown
            value={state.dataset}
            onChange={onChangeHandler}
            label="Dataset:"
            placeholder="Select One"
            name="dataset"
            menuItems={datasets}
            isReadOnly={isProjectDescriptionModalOpen}
          />

          <div className={classes.dateFieldContainer}>
            <p className={classes.hintText}>Historical Data...</p>
            <br />
            {process.env.REACT_APP_ENV === "preproduction" && (
              <CustomDropdown
                value={state.from}
                onChange={onChangeHandler}
                label="From:"
                placeholder="Select One"
                name="from"
                menuItems={historicalDataDpdOptions}
                // icon="calendar"
                isReadOnly={isProjectDescriptionModalOpen}
                myRef={historicalDpdRef}
              />
            )}

            {process.env.REACT_APP_ENV !== "preproduction" && (
              <LatLonTextField
                firstTitle="From"
                secondTitle="To"
                name1="from"
                name2="to"
                value1={historicalYear.from}
                value2={historicalYear.to}
                onChange={(e) =>
                  setHistoricalYear((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }))
                }
                isReadOnly={isProjectDescriptionModalOpen}
              />
            )}

            <p className={classes.hintText}>Projected Data...</p>
            <br />
            {process.env.REACT_APP_ENV === "preproduction" && (
              <CustomDropdown
                value={state.projection}
                onChange={onChangeHandler}
                label="Projection:"
                placeholder="Select One"
                name="projection"
                menuItems={projectedDataDpdOptions}
                isReadOnly={isProjectDescriptionModalOpen}
                myRef={projectionDpdRef}
              />
            )}

            {process.env.REACT_APP_ENV !== "preproduction" && (
              <LatLonTextField
                firstTitle="From"
                secondTitle="To"
                name1="from"
                name2="to"
                value1={projectedYear.from}
                value2={projectedYear.to}
                onChange={(e) =>
                  setProjectedYear((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }))
                }
                isReadOnly={isProjectDescriptionModalOpen}
              />
            )}
          </div>

          <CSSTransition
            in={!isProjectDescriptionModalOpen}
            timeout={300}
            classNames="item"
            unmountOnExit
          >
            <div className={classes.btnContainer}>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                className={classes.btn}
                style={{ marginRight: "16px" }}
                disabled={!doesEachFieldOfConfigDatasetCardHasValue}
                onClick={clearAllHandler}
              >
                Clear All
              </Button>
              <Button
                color="secondary"
                disableElevation
                variant="contained"
                className={classes.btn}
                disabled={!doesEachFieldOfConfigDatasetCardHasValue}
                onClick={() => {
                  setIsDatasetSelectionModalOpen(false);
                  onRetriveData();
                }}
              >
                Continue
              </Button>
            </div>
          </CSSTransition>
        </SearchBox>
      </div>
    </CSSTransition>
  );
};

export default ConfigureDataset;

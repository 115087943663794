import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const UploadIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.58824 8.41177V4.52941H0L4.52941 0L9.05882 4.52941H6.47059V8.41177H2.58824ZM0 11V9.70588H9.05882V11H0Z"
        fill="#1E75C3"
      />
    </SvgIcon>
  );
};

export default UploadIcon;

import React from "react";
import { Typography, Badge } from "@material-ui/core";
import { CardActionArea } from "@material-ui/core";

import DoneIcon from "../../../Icons/DoneIcon";

import {
  AvailableDatasetCardPropTypes,
  useAvailableDatasetCardStyles,
} from "./useStyles";

const AvailableDatasetCard = (props: AvailableDatasetCardPropTypes) => {
  const classes = useAvailableDatasetCardStyles(props);

  const { title, selected, onSelect, style, isAvailable } = props;

  return (
    <div
      className={classes.root}
      style={{ pointerEvents: isAvailable ? "auto" : "none" }}
    >
      <Badge color="secondary" badgeContent="Coming soon">
        <CardActionArea onClick={onSelect} style={{ width: "fit-content" }}>
          <div
            className={classes.cardContainer}
            style={{ ...style, backgroundSize: "contain" }}
          >
            <div style={{ alignSelf: "flex-start" }}>
              <Typography variant="h4" className={classes.mainTitle}>
                {title}
              </Typography>
             
            </div>
            <span
              className={classes.circle}
              style={{
                background: selected ? "#7CD4F2" : "#767676",
                cursor: "pointer",
              }}
            >
              <DoneIcon style={{ fontSize: "18px" }} />
            </span>
          </div>
        </CardActionArea>
      </Badge>
    </div>
  );
};

export default AvailableDatasetCard;

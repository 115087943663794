import React, { useState } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { CustomDropdownPropTypes, useCustomDropdownStyles } from "./useStyles";
import CalendarIcon from "../Icons/CalendarIcon";

const CustomDropdown = (props: CustomDropdownPropTypes) => {
  const classes = useCustomDropdownStyles(props);

  const {
    label,
    placeholder,
    menuItems,
    name,
    onChange,
    value,
    icon,
    isDropdownOpen,
    openDropdownHandler,
    closeDropDownHandler,
    isReadOnly,
    myRef,
  } = props;

  const [open, setOpen] = useState(false);

  const openDefaultDpdHandler = () => {
    setOpen(true);
  };

  const closeDefaultDpdHandler = () => {
    setOpen(false);
  };

  return (
    <div
      style={{ margin: "17px 0", pointerEvents: isReadOnly ? "none" : "auto" }}
    >
      <FormControl
        variant="outlined"
        fullWidth
        style={{ flexDirection: "row" }}
        className={classes.dpdContainer}
      >
        <div className={classes.labelContainer}>
          <label className={classes.labels} htmlFor={label}>
            {label}
          </label>
        </div>{" "}
        <Select
          inputRef={myRef}
          name={name}
          onChange={onChange}
          SelectDisplayProps={{
            style: {
              paddingTop: 8,
              paddingBottom: 8,
            },
          }}
          className={classes.dpd}
          id={label}
          displayEmpty
          onClose={
            closeDropDownHandler === undefined
              ? closeDefaultDpdHandler
              : closeDropDownHandler
          }
          onOpen={
            openDropdownHandler === undefined
              ? openDefaultDpdHandler
              : openDropdownHandler
          }
          value={value}
          IconComponent={() => (
            <div
              className={classes.dpdIconContainer}
              style={{ color: value ? "black" : "#9B9B9B" }}
              onClick={
                openDropdownHandler === undefined
                  ? openDefaultDpdHandler
                  : openDropdownHandler
              }
            >
              {icon === "calendar" ? (
                <CalendarIcon style={{ fontSize: "11px" }} />
              ) : (
                <ArrowDropDownIcon />
              )}
            </div>
          )}
          open={isDropdownOpen === undefined ? open : isDropdownOpen}
        >
          <MenuItem disabled value="">
            {placeholder}
          </MenuItem>
          {menuItems.map((item, i) => (
            <MenuItem disabled={ label === "Region:"  && item !== "Africa" && item !== "North America" && item !== "East Asia" } key={i} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default CustomDropdown;

import React, { useContext, useEffect, useState } from "react";
import SearchBox from "../../partials/SearchBox";
import CustomDropdown from "../../partials/CustomDropdown";

import { Button, Chip } from "@material-ui/core";
import { LocationSelectContext } from "../../context/LocationSelectPageContext";
import { CSSTransition } from "react-transition-group";
import MoreThanLessThanTextField from "../../partials/MoreThanLessThanTextField";

import { doesStringContainThisText } from "../../../../utility/helperFunctions";

import {
  temperatureRelatedSources,
  windRelatedSources,
  humdityRelatedSources,
  snowRelatedSources,
  marineRelatedDataSources,
  precipitationRelatedDataSources,
} from "./WindRelatedVariablesModal/data";

import {
  SelectClimateVariablePropTypes,
  useSelectClimateVariableStyles,
} from "./useStyles";

const variables: Array<{ name: string; isAvailable: boolean }> = [
  { name: "Temperature", isAvailable: true },
  { name: "Wind", isAvailable: false },
  { name: "Humidity", isAvailable: false },
  { name: "Snow", isAvailable: false },
  { name: "Marine", isAvailable: false },
  { name: "Precipitation", isAvailable: true },
];

const SelectClimateVariable = (props: SelectClimateVariablePropTypes) => {
  const classes = useSelectClimateVariableStyles();

  const { isVisible } = props;

  const {
    selectedClimateVariableState,
    windRelatedVariablesModalState,
    configureDatasetVisibleState,
    variableSelectionModalState,
    datasetSelectionModalState,
    selectedClimateVariableSourceState,
    climateDatasetModalState,
    lessThanMoreThanValuesState,
    projectDescriptionModalState,
  } = useContext(LocationSelectContext);

  const {
    selectedClimateVariable,
    setSelectedClimateVariable,
  } = selectedClimateVariableState;

  const { setIsWindRelatedVariablesModalOpen } = windRelatedVariablesModalState;

  const {
    isConfigureDatasetVisible,
    setIsConfigureDatasetVisible,
  } = configureDatasetVisibleState;

  const { setIsVariableSelectionModalOpen } = variableSelectionModalState;

  const { setIsDatasetSelectionModalOpen } = datasetSelectionModalState;

  const { setIsClimateDatasetModalOpen } = climateDatasetModalState;

  const { setIsProjectDescriptionModalOpen } = projectDescriptionModalState;

  const {
    selectedClimateVariableSource,
    setSelectedClimateVariableSource,
  } = selectedClimateVariableSourceState;

  const {
    setLessThanMoreThanValues,
    lessThanMoreThanValues,
  } = lessThanMoreThanValuesState;

  const [
    shouldShowMoreThanLessThanField,
    setShouldShowMoreThanLessThanField,
  ] = useState(false);

  const [variableUnit, setVariableUnit] = useState("");

  const [state, setState] = useState({
    source: "",
  });

  const dropdownSelectHandler = (evt) => {
    const selectedValue = evt.target.value;

    setState({
      ...state,
      [evt.target.name]: selectedValue,
    });

    setSelectedClimateVariableSource(selectedValue);
  };

  useEffect(() => {
    if (state.source) {
      setIsVariableSelectionModalOpen(false);
      setIsWindRelatedVariablesModalOpen(true);
    }
    //eslint-disable-next-line
  }, [state.source]);

  useEffect(() => {
    if (selectedClimateVariableSource) {
      setState({
        ...state,
        source: selectedClimateVariableSource,
      });
    }

    if (
      doesStringContainThisText(state.source, "more than") ||
      doesStringContainThisText(state.source, "less than")
    ) {
      setShouldShowMoreThanLessThanField(true);
    } else {
      setShouldShowMoreThanLessThanField(false);
    }
    //eslint-disable-next-line
  }, [state.source, selectedClimateVariableSource]);

  //-------------------------------------------------------------------------------------
  const [dpdItems, setDpdItems] = useState<string[]>([]);

  useEffect(() => {
    switch (selectedClimateVariable.toLowerCase()) {
      case "temperature":
        {
          let items: string[] = [];
          temperatureRelatedSources.map((item) => items.push(item.title));
          setDpdItems(items);
          setVariableUnit("°C");
        }
        break;

      case "wind":
        {
          let items: string[] = [];
          windRelatedSources.map((item) => items.push(item.title));
          setDpdItems(items);
          setVariableUnit("m/s");
        }
        break;

      case "humidity":
        {
          let items: string[] = [];
          humdityRelatedSources.map((item) => items.push(item.title));
          setDpdItems(items);
          setVariableUnit("%");
        }
        break;

      case "snow":
        {
          let items: string[] = [];
          snowRelatedSources.map((item) => items.push(item.title));
          setDpdItems(items);
          setVariableUnit("m");
        }
        break;

      case "marine":
        {
          let items: string[] = [];
          marineRelatedDataSources.map((item) => items.push(item.title));
          setDpdItems(items);
          setVariableUnit("m");
        }
        break;

      case "precipitation":
        {
          let items: string[] = [];
          precipitationRelatedDataSources.map((item) => items.push(item.title));
          setDpdItems(items);
          setVariableUnit("mm");
        }
        break;

      default:
        break;
    }
  }, [selectedClimateVariable]);

  const updateThresholdLessThanMoreThanValuesHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    if (doesStringContainThisText(state.source, "less than")) {
      setLessThanMoreThanValues({
        ...lessThanMoreThanValues,

        thresholdValueLessThan: (value.replace(
          /[A-Za-z,/]/,
          ""
        ) as unknown) as number,
      });
    } else {
      setLessThanMoreThanValues({
        ...lessThanMoreThanValues,
        thresholdValueMoreThan: (value.replace(
          /[A-Za-z,/]/,
          ""
        ) as unknown) as number,
      });
    }
  };

  // if input field is not visibile then sets it's value to zero
  useEffect(() => {
    if (!shouldShowMoreThanLessThanField) {
      setLessThanMoreThanValues({
        thresholdValueLessThan: 0,
        thresholdValueMoreThan: 0,
      });
    }
    //eslint-disable-next-line
  }, [shouldShowMoreThanLessThanField]);

  // it returns value for the lessThanMoreThan field according to the less than or more than field
  const value = () => {
    if (doesStringContainThisText(state.source, "less than")) {
      return lessThanMoreThanValues.thresholdValueLessThan;
    }

    return lessThanMoreThanValues.thresholdValueMoreThan;
  };

  const closeModalsHandler = () => {
    setIsConfigureDatasetVisible(false);
    setIsClimateDatasetModalOpen(false);
    setIsDatasetSelectionModalOpen(false);
    setIsProjectDescriptionModalOpen(false);
  };

  const clearAllHandler = () => {
    setState({
      source: "",
    });
    setVariableUnit("");
    // setIsVariableSelectionModalOpen(true);
    setSelectedClimateVariableSource("");
    setLessThanMoreThanValues({
      thresholdValueLessThan: 0,
      thresholdValueMoreThan: 0,
    });
    setSelectedClimateVariable("");
  };

  return (
    <CSSTransition in={isVisible} timeout={300} classNames="item" unmountOnExit>
      <div>
        <SearchBox
          overlay={isConfigureDatasetVisible}
          title="Select Climate Variable..."
          isBtnDisabled={!state.source}
          editClickHandler={closeModalsHandler}
          infoClickHandler={() => {
            setIsVariableSelectionModalOpen(true);
            setIsWindRelatedVariablesModalOpen(false);
            closeModalsHandler();
          }}
        >
          <div className={classes.chipContainer}>
            {variables.map((item, i) => (
              <Chip
                key={i}
                onClick={() => {
                  setSelectedClimateVariable(item.name);
                  setIsVariableSelectionModalOpen(false);
                  setIsWindRelatedVariablesModalOpen(true);
                }}
                className={classes.chip}
                label={item.name}
                variant={
                  item.name === selectedClimateVariable ? "default" : "outlined"
                }
                style={{
                  color:
                    item.name === selectedClimateVariable ? "#000" : "#9B9B9B",
                  background:
                    item.name === selectedClimateVariable ? " #ADE5F7" : "",
                  border:
                    item.name === selectedClimateVariable
                      ? "1.21806px solid black"
                      : "",
                  pointerEvents:
                    isConfigureDatasetVisible || !item.isAvailable
                      ? "none"
                      : "auto",
                }}
                disabled={!item.isAvailable}
              />
            ))}
          </div>

          <CustomDropdown
            value={state.source}
            onChange={dropdownSelectHandler}
            name="source"
            menuItems={dpdItems}
            label="Source:"
            isReadOnly={isConfigureDatasetVisible}
          />

          <CSSTransition
            in={shouldShowMoreThanLessThanField}
            timeout={300}
            classNames="item"
            unmountOnExit
          >
            <MoreThanLessThanTextField
              value={value().toString()}
              unit={variableUnit}
              sign={
                doesStringContainThisText(state.source, "less than") ? "<" : ">"
              }
              onChange={updateThresholdLessThanMoreThanValuesHandler}
              isReadOnly={isConfigureDatasetVisible}
            />
          </CSSTransition>

          <CSSTransition
            in={!isConfigureDatasetVisible}
            timeout={300}
            classNames="item"
            unmountOnExit
          >
            <div className={classes.btnContainer}>
              <Button
                disabled={!state.source}
                disableElevation
                variant="contained"
                color="primary"
                className={classes.btn}
                style={{ marginRight: "16px" }}
                onClick={clearAllHandler}
              >
                Clear All
              </Button>
              <Button
                onClick={() => {
                  setIsConfigureDatasetVisible(true);
                  setIsVariableSelectionModalOpen(false);
                  setIsClimateDatasetModalOpen(true);
                  setIsWindRelatedVariablesModalOpen(false);
                }}
                color="secondary"
                disabled={!state.source}
                disableElevation
                variant="contained"
                className={classes.btn}
              >
                Select &amp; Continue
              </Button>
            </div>
          </CSSTransition>
        </SearchBox>
      </div>
    </CSSTransition>
  );
};

export default SelectClimateVariable;

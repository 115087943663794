import React from "react";
import { makeStyles } from "@material-ui/core";

export type CustomDropdownPropTypes = {
  label: string;
  placeholder?: string;
  menuItems: string[];
  name?: string;
  onChange?: (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => void;
  value?: unknown;
  icon?: "calendar";
  isDropdownOpen?: boolean;
  openDropdownHandler?: () => void;
  closeDropDownHandler?: () => void;
  isReadOnly?: boolean;
  myRef?: React.Ref<any>;
};

export const useCustomDropdownStyles = makeStyles({
  dpdContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "32px",
  },

  labelContainer: {
    width: "80px",
    height: "101%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1.21806px solid #BEBEBE",
    borderRight: "none",
    background: (props: CustomDropdownPropTypes) =>
      props.value ? "#ADE5F7" : "",
    borderColor: (props: CustomDropdownPropTypes) =>
      props.value ? "#000" : "#BEBEBE",
  },

  labels: {
    fontSize: "0.75rem",
    lineHeight: "14px",
    color: (props: CustomDropdownPropTypes) =>
      props.value ? "#000" : "#9B9B9B",
  },

  //dpd => dropdown
  dpd: {
    height: "100%",
    width: "240px",
    color: (props: CustomDropdownPropTypes) =>
      props.value ? "#000" : "#9B9B9B",

    "& .MuiSelect-select": {
      borderRadius: "0",
      outline: "none",
      fontSize: "12px",
      lineHeight: "14px",
      color: (props: CustomDropdownPropTypes) =>
        props.value ? "#000" : "#9B9B9B",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: (props: CustomDropdownPropTypes) =>
        props.value ? "#000" : "",
    },
  },
  dpdIconContainer: {
    width: "46px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    background: (props: CustomDropdownPropTypes) =>
      props.value ? "#ADE5F7" : "",
    border: (props: CustomDropdownPropTypes) =>
      props.value ? "1px solid black" : "",
    borderLeft: (props: CustomDropdownPropTypes) =>
      props.value ? "1.21806px solid #000" : "1.21806px solid #BEBEBE",
  },
});

export type PaginationPropTypes = {
  itemsPerPage: number;
  totalItems: number;
  paginate: (number: number) => void;
  currentPageNumber: number;
};

export const usePaginationStyles = makeStyles({
  indicator: {
    width: "16px",
    height: "16px",
    background: "#BEBEBE",
    borderRadius: "100%",
    marginLeft: "8px",
    display: "inline-block",
    cursor: "pointer",
  },
});

export type SearchBoxPropTypes = {
  title: string;
  children: React.ReactNode;
  isBtnDisabled?: boolean;
  editClickHandler?: () => void;
  infoClickHandler?: () => void;
  overlay: boolean;
};

export const useSearchBoxStyles = makeStyles({
  mainCard: {
    width: "368px",
    padding: "17px 24px 24px 24px",
    boxShadow: "none",
    borderRadius: "0",
    marginBottom: "1rem",
    position: "relative",

    "&:before": {
      content: "''",
      position: "absolute",
      top: "58px",
      left: 0,
      width: (props: SearchBoxPropTypes) => (props.overlay ? "100%" : 0),
      height: (props: SearchBoxPropTypes) => (props.overlay ? "100%" : 0),
      backgroundColor: (props: SearchBoxPropTypes) =>
        props.overlay ? "rgba(0, 0, 0, .5)" : "transparent",
      zIndex: (props: SearchBoxPropTypes) => (props.overlay ? 1 : 0),
    },
  },
  cardHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardHeaderHeading: {
    fontSize: "1rem",
    lineHeight: "19px",
    color: "#1C1C1C",
  },
  icon: {
    fontSize: "16px",
  },

  btnContainer: {
    display: "flex",
    width: "100%",
  },

  btn: {
    width: "152px",
    fontSize: "0.75rem",
    lineHeight: "14px",
    fontWeight: "normal",
    textTransform: "capitalize",
  },
});

export type FilterPropTypes = {
  filterOptions: string[];
  selectedFilterOption: string;
  onClick: (item: string) => void;
  style?: React.CSSProperties;
};

export const useFilterStyles = makeStyles({
  filterText: {
    fontSize: "12px",
    lineHeight: "14px",
    color: "#303030",
    textTransform: "capitalize",
  },
  chip: {
    width: "90px",
    height: "24px",
    borderRadius: "19.4889px",
    fontSize: "12px",
    lineHeight: "14px",
    background: "#BEBEBE",
    color: "#303030",
    marginLeft: "9.8px",
  },
});

export type ReadOnlyLatLonTextFieldPropTypes = {
  lat: number | string;
  lon: number | string;
  onDeleteHandler: () => void;
  disabled?: boolean;
  className: string;
};

export const useReadOnlyLatLonTextFieldStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    margin: "8px 0",
  },
  labelContainer: {
    width: "56px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1.21806px solid #000",
    borderRight: "none",
    background: "#ADE5F7",
  },
  labels: {
    fontSize: "12px",
    lineHeight: "14px",

    color: "black",
  },
  displayValueContainer: {
    width: "88px",
    height: "32px",
    border: "1.21806px solid #000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
  },
});

export type CustomModalPropTypes = {
  isOpen: boolean;
  title: string | React.ReactNode;
  subTitle?: string;
  paragraph?: string | React.ReactNode;
  children: React.ReactNode;
  onClick?: () => void;
  headingMaxWidth?: string | number;
  headingFontSize?: string | number;
  headingBottomText?: string;
  justifyContent?: "flex-start" | "flex-end" | "space-between" | "space-around";
  width?: number | string;
};

export const useCustomModalStyles = makeStyles({
  mainContainer: {
    maxWidth: (props: CustomModalPropTypes) =>
      props.width ? props.width : "63rem",

    height: "calc(100vh - 64px)",
    position: "relative",
    marginBottom: "16px",
    background: "#fff",
    padding: "2.75rem 24px 4.3125rem 3rem",
    transition: "all .3s ease-in-out",
  },
  icon: {
    position: "absolute",
    right: "24px",
    top: "24px",
  },
  mainHeading: {
    maxWidth: "21rem",
    // height: "9.5625rem",
    fontSize: "2.25rem",
    lineHeight: "42px",
    color: "#1C1C1C",
    marginBottom: "8px",
  },

  mainHeadingSubHeading: {
    fontWeight: 300,
    fontSize: "1.5rem",
    lineHeight: "28px",
    color: "#1C1C1C",
    display: "block",
  },

  subHeading: {
    maxWidth: "18.3125rem",
    // height: "8rem",
    fontWeight: 300,
    fontSize: "1.5rem",
    lineHeight: "28px",
    color: "#1E75C3",
  },
  paragraph: {
    width: "365px",
    height: "170px",
    fontSize: "0.875rem",
    lineHeight: "15px",
    color: "#1C1C1C",
  },
});
